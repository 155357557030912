import { getUserDetailApi } from "../../../services/api_services/users";
import GroomInfo from "./GroomInfo";
import PalaceInfo from "./PalaceInfo";
import BasicInfo from "./BasicInfo";
import { UserStateInterface } from "../../../services/dataType/api_interfaces/users";
import { Box, Tab, Tabs, Typography, createContext, useEffect, useParams, useState } from "../../../utils/commonImports";
import { translateLanguage } from "../../../translation";
import AddUserNotes from "./AddUserNotes";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const initialUserData: UserStateInterface = ({
    data: {
        userBasicInfo: {
            isGroomProfile: false,
            isPalaceProfile: false,
        },
        groomBasicInfo: {},
        groomPaymentsInfo: [],
        palaceBasicInfo: {},
    },
    loading: true,
})


export const UserDataContext: any = createContext(initialUserData);

function CustomTabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
        </div>
    );
}

const UserDetailsPage = () => {
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [userData, setUserData] = useState(initialUserData);

    const { userId } = useParams();

    useEffect(() => {
        userId &&
            userData.loading &&
            getUserDetailApi(userId, setUserData);
    }, [
        userId,
        userData.loading
    ]);

    return (
        <>
            <UserDataContext.Provider value={{ userData: userData, setUserData: setUserData }} >
                <Box>
                    <BasicInfo userData={userData.data.userBasicInfo} />
                    <Box className="!pb-5 !pt-2 !px-5" sx={{ maxWidth: "2000px" }}>
                        <Box>
                            <Tabs
                                value={selectedTab}
                                onChange={(event, value) => {
                                    setSelectedTab(value);
                                }}
                                className=" commonBorderRadius commonShadow "
                                aria-label="basic tabs example"
                            >
                                <Tab
                                    className="!font-semibold lg:!min-w-[200px]"
                                    label={translateLanguage("GROOM_PROFILE")}
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    className="!font-semibold lg:!min-w-[200px]"
                                    label={translateLanguage("WEDDING_HALL_PROFILE")}
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        </Box>

                        <CustomTabPanel value={selectedTab} index={0}>
                            {userData.data.userBasicInfo.isGroomProfile ? (
                                <GroomInfo />
                            ) : (
                                <Typography variant="h6" className="!font-semibold NunitoSans800">
                                    {translateLanguage("NO_GROOM_PROFILE_EXISTS")}
                                </Typography>
                            )}
                        </CustomTabPanel>

                        <CustomTabPanel value={selectedTab} index={1}>
                            {userData.data.userBasicInfo.isPalaceProfile ? (
                                <PalaceInfo userData={userData.data.palaceBasicInfo} />
                            ) : (
                                <Typography variant="h6" className="!font-semibold NunitoSans800">
                                    {translateLanguage("NO_HALL_PROFILE_EXISTS")}
                                </Typography>
                            )}
                        </CustomTabPanel>
                    <AddUserNotes userId={userId} notes={userData?.data?.userBasicInfo}/>
                    </Box>
                </Box>
            </UserDataContext.Provider>
        </>
    );
};

export default UserDetailsPage;
