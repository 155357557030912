export const ADMIN_LOGIN = "login/";
export const ADMIN_LOGOUT = "logout/";


export const USERS_LIST = "users/list/"
export const USER_DETAIL = "user/detail/"


export const OPPORTUNITY_LIST = "opportunity/list/"
export const OPPORTUNITY_DETAIL = "opportunity/detail/"


export const STATE_CITY_LIST = "state_city/list/"
export const STATE_ADD = "state/"
export const CITY_ADD = "city/"



export const APP_VERSION = "admin_app_version/"
export const DASHBOARD = "dashboard/"


export const RELATION_LIST = "relation_ship/list/"
export const RELATION_ADD = "relation_ship/"


export const TOASTER_ALERT_LIST = "toaster_alert/list/"
export const TOASTER_ALERT_ADD = "toaster_alert/content/add/"


export const CUSTOM_MENU_CARD_LIST = "custom_card/list/"
export const CUSTOM_MENU_CARD_ADD = "custom_card/"

export const FEEDBACK_LIST = "feedback_list/"
export const MAKE_PAYMENT = "make_payment/"
export const TOGGLE_DEFAULT_OTP = "toggle_default_otp/"

export const FEATURED_SERVICES_LIST = "featured_services_list/"
export const PAYMENTS_LIST = "payments_list/"
export const GROOM_PAYMENT_DETAIL = "groom_payment_detail/"

export const SEND_PENDING_CARD_ATTACHMENT = "pending_card/attach/"
export const FEATURED_SERVIVCE_DETAIL = "featured_service/detail/"

export const ACTIVE_INACTIVE = "active-inactive/"

export const ADD_UPDATE_NOTES = "user/notes/"