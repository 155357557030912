import * as React from 'react';
import Box from '@mui/material/Box';
import { Tab, Tabs } from '@mui/material';
import PendingCustomCardDatagrid from '../../components/Datagrids/PendingCustomCardDatagrid';
import CommonHeaderText from '../../components/common/CommonHeaderText';
import { translateLanguage } from '../../translation';


export default function PendingCustomCard() {
    const [paginationReset, setPaginationReset] = React.useState<boolean>(false);
    const [cardStatus, setCardStatus] = React.useState(localStorage.getItem('selectedCustomCardRequestTab') || "pending");
    const handleTabChange = (event: any, newValue: any) => {
        setCardStatus(newValue);
        localStorage.setItem('selectedCustomCardRequestTab', newValue);
        setPaginationReset(true); // Trigger pagination reset
    };

    return (
        <>
            <Box className="!py-5 !px-5" sx={{ maxWidth: "2000px" }}>
                <Box className="">
                    <CommonHeaderText headerName={translateLanguage("CUSTOM_CARD_REQUEST")} />
                </Box>
                <div className='!mb-5 !gap-5 lg:!flex !items-center !justify-between !mt-4' >

                    <Box className="md:!flex  !items-center !gap-3 !overflow-hidden">
                        <Box className="!border commonShadow !bg-[var(--primary)] !w-fit tabsRadius">
                            <Tabs value={cardStatus} onChange={handleTabChange} className=" !overeflow-scroll tabsScroll" >
                                <Tab className='lg:!text-base lg:!min-w-[180px] !text-sm !font-semibold'
                                    label={translateLanguage("PENDING")}
                                    value="pending"
                                />
                                <Tab className='lg:!text-base lg:!min-w-[180px] !text-sm !font-semibold'
                                    label={translateLanguage("COMPLETED")}
                                    value="completed"
                                />
                            </Tabs>
                        </Box>
                    </Box>

                </div>
                <PendingCustomCardDatagrid cardStatus={cardStatus} paginationReset={paginationReset} setPaginationReset={setPaginationReset} />
            </Box>
        </>
    )
}