const translations: Record<string, Record<string, string>> = {
    "en": require('./tranlations/translation_en.json'),
    "ar": require('./tranlations/translation_ar.json')
};

const locale = process.env.REACT_APP_LOCALE;

export const translateLanguage = (key: string) => {
    // let langauge = (locale === "ar") ? "ar" : "en"
    let langauge = "ar";

    // if (localStorage.getItem('language')) {
    //     langauge = localStorage.getItem('language') || "en"
    // }

    let returnText: string = translations[langauge][key]
    return returnText
}

export default translations;
