
import { DataGrid, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { RootStateType } from '../../store';
import moment from 'moment';
import { listRequestInterface } from '../../services/dataType/api_interfaces/users';
import { PendingCardsListAPI } from '../../services/api_services/featuredServices';
import ProfileNameEmailUser from '../common/ProfileNameEmailUser';
import { Link, useSearchParams } from 'react-router-dom';
import ViewIconButton from '../common/ViewIconButton';
import { translateLanguage } from '../../translation';
import { convertToHijri } from '../../utils/convertToHijri';

const customLocaleText = {
    noRowsLabel: translateLanguage("NO_PENDING_CUSTOM_CARD"),
};

interface pendingCustomCardInterface {
    cardStatus: string;
    paginationReset: boolean;
    setPaginationReset: (value: boolean) => void;
}

export default function PendingCustomCardDatagrid(props: pendingCustomCardInterface) {
    const { cardStatus } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const generateColumns = (): GridColDef[] => {
        return [
            { field: "id", headerName: "ID", width: 30, flex: 1, },
            {
                field: 'groom', headerName: translateLanguage("GROOM"), minWidth: 200, flex: 1.4, headerAlign: 'left', sortable: true,
                align: "left", valueGetter: (params) => params?.row?.requestedBy?.name,
                renderCell: (params) => {
                    return (
                        <>
                            <ProfileNameEmailUser
                                profileImageAvatar={params.row.requestedBy.photo} profileName={params.row.requestedBy.name} />
                        </>
                    );
                }
            },
            {
                field: 'amount', headerName: (
                    <>
                        {translateLanguage("AMOUNT")}&nbsp;
                        <span className='!text-xs !font-semibold !text-[#566370]'>(SAR)</span>
                    </>
                ) as any, minWidth: 130, flex: 1, sortable: true,
                renderCell: (params) => {
                    return (
                        <Box className="!pl-5">
                            {params.row.amount}
                        </Box>
                    );
                }
            },
            {
                field: 'createdAt', headerName: translateLanguage("REQUESTED_AT"), minWidth: 200, flex: 1, sortable: true,
                renderCell: (params) => {
                    return (
                        <Box className="!pl-5">
                            {convertToHijri(params.row.createdAt)}
                            <br />
                            {moment(params.row.createdAt).format('h:mm A')}
                        </Box>
                    );
                }
            },
            {
                field: 'weddingDate',
                headerName: translateLanguage("WEDDING_DATE"),
                minWidth: 200,
                flex: 1,
                sortable: true,
                valueGetter: (params) => {
                    return new Date(params.row.requestedBy?.weddingDate);
                },
                renderCell: (params) => {
                    const dateWedding = new Date(params.row.requestedBy?.weddingDate);
                    return (
                        <Box className="!pl-5">
                            {convertToHijri(dateWedding as any)}
                            <br />
                            {moment(dateWedding).format('h:mm A')}
                        </Box>
                    );
                }
            },
            {
                field: 'guestNumbers', headerName: translateLanguage("GUEST_NUMBERS"), minWidth: 210, flex: 1.5, sortable: false,
                renderCell: (params) => {
                    const { guestNumbers, id } = params.row;
                    const splitGuestNumber = guestNumbers?.split("\n");
                    const sliceGuestNumber = splitGuestNumber?.slice(0, 3);
                    const updatedGuestNumber = sliceGuestNumber?.join(', ') || <Box className="!pl-16">---</Box>
                    return (
                        <>
                            <Typography className='!overflow-hidden NunitoSans500'>
                                {updatedGuestNumber}
                            </Typography>
                            {splitGuestNumber?.length > 0 ? (
                                <Box>
                                    {splitGuestNumber?.length > 2 && (
                                        <>
                                            ...
                                        </>
                                    )}
                                </Box>
                            ) : (
                                <Typography className='!ml-[-130px] '>
                                    ---
                                </Typography>
                            )}
                        </>
                    );
                }
            },
            {
                field: 'action', headerName: translateLanguage("ACTION"), minWidth: 150, flex: 0.4, sortable: false,
                renderCell: (params) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Link to={`/pending-cards/detail/${params.row.id}`}>
                                <ViewIconButton />
                            </Link>
                        </div>
                    );
                }
            },
        ]
    };

    const columns = generateColumns();
    let pendingCards = useSelector((state: RootStateType) => state.pendingCardStore.pendingCardList);
    let totalPendingCardsCount = useSelector((state: RootStateType) => state.pendingCardStore.totalPendingCardCount);
    let tableLoader = useSelector((state: RootStateType) => state.alerts.tableLoader);

    const [columnVisibilityModel, setColumnVisibilityModel] =
        useState<GridColumnVisibilityModel>({
            id: false
        });

    // const [paginationModel, setPaginationModel] = useState({
    //     pageSize: 10,
    //     page: 0,
    // });

    const page = Number(searchParams.get("page")) || 0;
    const pageSize = Number(searchParams.get("pageSize")) || 10;

    // Handle pagination changes
      const handlePaginationChange = (model: { page: number; pageSize: number }) => {
        searchParams.set("page", model.page.toString());
        searchParams.set("pageSize", model.pageSize.toString());
        setSearchParams(searchParams);
    };

    useEffect(() => {
        const apiRequestData: listRequestInterface = {
            pageLimit: pageSize,
            pageNumber: page + 1,
            cardStatus: cardStatus
        }
        PendingCardsListAPI(apiRequestData)
    }, [pageSize,page, cardStatus])


    useEffect(() => {
        if (props?.paginationReset) {
            searchParams.set("page", "0");  // Reset page to 0
            searchParams.set("pageSize", pageSize.toString());
            setSearchParams(searchParams);
            props?.setPaginationReset(false); // Reset the flag after applying
        }
    }, [props?.paginationReset]);


    return (
        <>
            <div style={{ width: '100%', maxWidth: "2100px" }} className='dataGridTableFeaturedService'>
                <DataGrid
                    sx={{
                        '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer'
                        },
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: "#60c4f08f",
                            fontWeight: 1000,
                            fontSize: 18,
                        },
                        height: "90vh",
                        minHeight: "70vh"
                    }}
                    autoHeight={true}
                    rows={pendingCards}
                    columns={columns}
                    getRowId={(user) => user.id}
                    rowCount={totalPendingCardsCount}
                    pageSizeOptions={[10, 25, 50]}
                    localeText={customLocaleText}
                    columnVisibilityModel={columnVisibilityModel}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    rowHeight={85}
                    className='tableHeaderColor commonBorderRadius commonShadow'
                    disableVirtualization={true}
                    paginationMode={'server'}
                    paginationModel={{ page, pageSize }}
                    onPaginationModelChange={handlePaginationChange}
                    hideFooterSelectedRowCount={true}
                    disableRowSelectionOnClick={true}
                />
            </div>

        </>
    );
}
