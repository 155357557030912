import { Typography } from '@mui/material';

const CommonHeaderText = (props?: any) => {
    const { className, style, variant, headerName } = props;
    return (
        <Typography style={style} variant={variant}
            className={`!text-[20px] md:!text-[32px] NunitoSans700  ${className}`}
        >
            {headerName}
        </Typography>
    )
}

export default CommonHeaderText