import { Typography, TextField, Button, Box, FormHelperText } from '@mui/material';
import { Form, Field } from 'react-final-form';
import { translateLanguage } from '../../../translation';
import axios from "axios";
import { useDispatch } from 'react-redux';
import { ShowSuccessAlert, ShowErrorAlert } from '../../../store/alerts';
import { ADD_UPDATE_NOTES } from '../../../constants';
import { getApiPath, getConfigSetting } from '../../../utils/services';

const AddUserNotes = ({ userId, notes }:any) => {
  const dispatch = useDispatch();

  const validate = (values:any) => {
    const errors = {} as any;
    if (!values.adminNotes?.trim()) {
      errors.adminNotes = "Admin Notes are required";
    }
    return errors;
  };

  const onSubmit = async (values:any, form:any) => {
    try {
      await axios.post(getApiPath(ADD_UPDATE_NOTES), {
        user_id: userId,
        admin_notes: values.adminNotes.trim(),
      }, getConfigSetting());

      const message = notes?.admin_notes ? "Notes updated successfully" : "Notes added successfully";
      dispatch(ShowSuccessAlert({ visible: true, message }));
    } catch (err:any) {
      console.error("Error saving note:", err);
      const errorMessage = err?.response?.data?.message || "Failed to save note";
      dispatch(ShowErrorAlert({ visible: true, message: errorMessage }));
    }
  };

  const styles = {
    marginBottom: "5px",
    boxShadow: "rgba(0, 0, 0, 0.08) 0px 0px 7px 1px",

    '& input[type=number]': {
        '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
};

  return (
    <Box className="commonBorderRadius commonShadow p-5 !bg-white mt-4" sx={{ maxWidth: "2000px" }}>
      <Typography style={{ fontWeight: 600, marginBottom: "20px" }} className="!text-lg NunitoSans700">
        {translateLanguage("ADD_NOTES")}
      </Typography>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{ adminNotes: notes?.admin_notes ?? "" }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className='!flex !flex-col !items-start'>
            <Field name="adminNotes">
              {({ input, meta }) => (
                <>
                  <TextField
                    {...input}
                    // label="Admin Notes"
                    variant="outlined"
                    multiline
                    rows={4}
                    fullWidth
                    sx={{...styles}}
                    error={meta.touched && meta.error}
                    className='!w-full md:!w-1/2'
                  />
                  {meta.touched && meta.error && (
                    <FormHelperText error>{meta.error}</FormHelperText>
                  )}
                </>
              )}
            </Field>
            <Button
              type="submit"
              variant="contained"
              className={`!font-semibold NunitoSans800 commonButtonRadius !px-8 !py-[10px] !text-base ${submitting ? '!bg-grey-600' : '!bg-[var(--primary)]'}`}
              disabled={submitting}
              sx={{ mt: 2 }}>
              {submitting ? "Submitting..." : notes?.admin_notes ? "Update Notes" : "Add Notes"}
            </Button>
          </form>
        )}
      />
    </Box>
  );
};

export default AddUserNotes;
