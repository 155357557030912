import OpportunityDatagrid from '../../components/Datagrids/OpportunityDatagrid';
import CommonHeaderText from '../../components/common/CommonHeaderText';
import SearchBox from '../../components/common/SearchBox';
import { translateLanguage } from '../../translation';
import { Box, Tab, Tabs, useState } from '../../utils/commonImports';

export default function OpportunityList() {
    const [searchText, setSearchText] = useState("")
    const [filterType, setFilterType] = useState("upcoming")

    const handleTabChange = (event: any, newValue: any) => {
        setFilterType(newValue);
    };
    return (
        <>
            <div className="!py-5 !px-5">
                <CommonHeaderText headerName={translateLanguage("OPPORTUNITIES")} />
                <div className="!mb-3 !w-full !mt-4" style={{ display: "flex", }}>
                    <Box className="lg:!flex !grid !items-center !gap-3 !justify-between !w-full !overflow-hidden">
                        <Box className="!border commonShadow !bg-[var(--primary)]  tabsRadius">
                            <Tabs value={filterType} onChange={handleTabChange} className=" !overeflow-scroll tabsScroll" >
                                <Tab className='lg:!text-base xl:!min-w-[200px] !text-sm !font-semibold'
                                    label={translateLanguage("UPCOMING")}
                                    value="upcoming"
                                />
                                <Tab className='lg:!text-base xl:!min-w-[200px] !text-sm !font-semibold'
                                    label={translateLanguage("PAST_WEDDINGS")}
                                    value="past"
                                />
                            </Tabs>
                        </Box>
                        <SearchBox searchText={searchText} setSearchText={setSearchText} />
                    </Box>
                </div>
                <OpportunityDatagrid searchText={searchText} filterType={filterType} />
            </div>
        </>
    )
}
