import { Card, CardContent, Grid, Typography, Link, Box } from '@mui/material'
import { useEffect, useState } from 'react';
import { getDasboardDataAPI } from '../../services/api_services/dashboard';
import { Link as RouterLink } from 'react-router-dom';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PushPinSharpIcon from '@mui/icons-material/PushPinSharp';
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AssistantIcon from '@mui/icons-material/Assistant';
import CommonHeaderText from '../../components/common/CommonHeaderText';
import { translateLanguage } from '../../translation';

const useStyles = () => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '156px',
    textAlign: "center",
    height: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "rows",
    alignItems: "center",
    height: "100%",
    width: '100%',
  },
  title: {
    marginBottom: '8px',
    textAlign: 'left',

  },
  count: {
    marginBottom: '4px',
    textAlign: 'right',

  },
  icon: {
    textAlign: 'left',
  },
});



export default function Dashboard() {
  const classes = useStyles();

  const [dashboardData, setDashboardData] = useState({
    totalUsers: 0,
    totalOpportunities: 0,
    totalStates: 0,
    totalCities: 0,
    totalGrooms: 0,
    totalPalace: 0,
    totalPendingCustomCards: 0,
  })

  useEffect(() => {
    getDasboardDataAPI(setDashboardData)
  }, [])

  return (
    <Box className="!px-5 !py-5 defaultBasicInfo" sx={{ maxWidth: "2000px" }}>
      <CommonHeaderText headerName={translateLanguage("DASHBOARD")} />
      <Box className="!mb-4 !mt-6">
        <Grid container spacing={3} className='breakPointLargeWidth'>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
            <Link component={RouterLink} to="/users" underline="none" >
              <Card sx={{
                ...classes.card,
                backgroundColor: "#198df114",
              }}
                className='!bg-[var(--white)] commonShadow commonBorderRadius '
              >
                <CardContent sx={classes.content} className='lg:!justify-between !justify-between !items-center'>
                  <div>
                    <Typography variant="h6" component="h6" sx={classes.title} className="NunitoSans600 !text-[var(--gray-font)] !text-[16px]">
                      {translateLanguage("TOTAL")} {translateLanguage("USERS")}
                    </Typography>
                    <Typography variant="h4" component="p" className='!text-center NunitoSans700 !text-[28px]' sx={classes.count}>
                      {dashboardData.totalUsers}
                    </Typography>
                  </div>
                  <PersonOutlineOutlinedIcon className="!text-[48px] md:!text-[58px] !rounded-full !p-3  !text-[var(--orange)] !bg-[var(--orange-light)]" sx={classes.icon} />
                </CardContent>
              </Card>
            </Link>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
            <Link component={RouterLink} to="/opportunity" underline="none" >

              <Card sx={{
                ...classes.card,
                backgroundColor: "#198df114",
              }}
                className='!bg-[var(--white)] commonShadow commonBorderRadius '
              >
                <CardContent sx={classes.content} className='lg:!justify-between !justify-between !items-center'>
                  <div>
                    <Typography variant="h6" component="h2" sx={classes.title} className="NunitoSans600 !text-[var(--gray-font)] !text-[16px]">
                      {translateLanguage("OPPORTUNITIES")}
                    </Typography>
                    <Typography variant="h4" component="p" className='!text-center NunitoSans700 !text-[28px]' sx={classes.count}>
                      {dashboardData.totalOpportunities}
                    </Typography>
                  </div>
                  <PushPinSharpIcon style={{ transform: "rotate(40deg)" }} className="!text-[48px] md:!text-[58px] !rounded-full !p-3  !text-[var(--yellow)] !bg-[var(--yellow-light)]" sx={classes.icon} />
                </CardContent>
              </Card>
            </Link>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
            <Link component={RouterLink} to="/state-city" underline="none">

              <Card sx={{
                ...classes.card,
                backgroundColor: "#198df114",
              }}
                className='!bg-[var(--white)] commonShadow commonBorderRadius'
              >
                <CardContent sx={classes.content} className='lg:!justify-between !justify-between !items-center'>
                  <div>
                    <Typography variant="h6" component="h2" sx={classes.title} className="NunitoSans600 !text-[var(--gray-font)] !text-[16px]">
                      {translateLanguage("STATES")}
                    </Typography>
                    <Typography variant="h4" component="p" className='!text-center NunitoSans700 !text-[28px]' sx={classes.count}>
                      {dashboardData.totalStates}
                    </Typography>
                  </div>

                  <div>
                    <Typography variant="h6" component="h2" sx={classes.title} className="NunitoSans600 !text-[var(--gray-font)] !text-[16px]">
                      {translateLanguage("CITIES")}
                    </Typography>
                    <Typography variant="h4" component="p" className='!text-center NunitoSans700 !text-[28px]' sx={classes.count}>
                      {dashboardData.totalCities}
                    </Typography>
                  </div>

                  <LocationOnOutlinedIcon className="!text-[48px] md:!text-[58px] !rounded-full !p-3   !text-[var(--purple)] !bg-[var(--purple-light)]" sx={classes.icon} />
                </CardContent>
              </Card>
            </Link>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>

            <Link component={RouterLink} to="/pending-cards" underline="none" >
              <Card sx={{
                ...classes.card,
                backgroundColor: "#198df114",
              }}
                className='!bg-[var(--white)] commonShadow commonBorderRadius'
              >
                <CardContent sx={classes.content} className='lg:!justify-between !justify-between !items-center'>
                  <div>
                    <Typography variant="h6" component="h2" sx={classes.title} className="NunitoSans600  !text-[var(--gray-font)] !text-[16px]">
                      {translateLanguage("CUSTOM_CARD_REQUEST")}
                    </Typography>
                    <Typography variant="h4" component="p" className='!text-center NunitoSans700 !text-[28px]' sx={classes.count}>
                      {dashboardData.totalPendingCustomCards}
                    </Typography>
                  </div>
                  <AssistantIcon className="!text-[48px] md:!text-[58px] !rounded-full !p-3  !text-[var(--teal-card)] !bg-[var(--teal-card-light)]" sx={classes.icon} />
                </CardContent>
              </Card>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
