import React from 'react';
import UsersDatagrid from '../../components/Datagrids/UsersDatagrid';
import CommonHeaderText from '../../components/common/CommonHeaderText';
import SearchBox from '../../components/common/SearchBox';
import { Box, Tab, Tabs, Typography, useState } from '../../utils/commonImports';
import { translateLanguage } from '../../translation';

interface UserListProps {
}

export default function UserList(props: UserListProps) {
    const [searchText, setSearchText] = useState("")

    const [filterType, setFilterType] = React.useState(localStorage.getItem('selectedUserListTab') || 'all')
    const handleTabChange = (event: any, newValue: any) => {
        setFilterType(newValue);
        localStorage.setItem('selectedUserListTab', newValue)
    };


    return (
        <>
            <div className="!py-5 !px-5">
                <Box className="">
                    <CommonHeaderText headerName={translateLanguage("USERS")} />
                </Box>
                <div className='!mb-4 !gap-5 lg:!flex !items-center !mt-4' >
                    <Box className="!flex !items-center !w-full  !gap-2">
                        <Box className="lg:!flex !grid !items-center !gap-3 !justify-between !w-full !overflow-hidden">
                            <Box className="!border commonShadow !bg-[var(--primary)] tabsRadius ">
                                <Tabs value={filterType} onChange={handleTabChange} className=" !overeflow-scroll tabsScroll" >
                                    <Tab className='lg:!text-base xl:!min-w-[140px] !text-sm !font-semibold'
                                        label={translateLanguage("ALL")}
                                        value="all"
                                    />
                                    <Tab className='lg:!text-base xl:!min-w-[140px] !text-sm !font-semibold'
                                        label={translateLanguage("MURFID")}
                                        value="murfid"
                                    />
                                    <Tab className='lg:!text-base xl:!min-w-[140px] !text-sm !font-semibold'
                                        label={translateLanguage("GROOM")}
                                        value="groom"
                                    />
                                    <Tab className='lg:!text-base xl:!min-w-[140px] !text-sm !font-semibold'
                                        label={translateLanguage("PALACE")}
                                        value="palace"
                                    />
                                </Tabs>
                            </Box>
                            <SearchBox searchText={searchText} setSearchText={setSearchText} />
                        </Box>
                    </Box>

                </div>
                <UsersDatagrid searchText={searchText} filterType={filterType} />
            </div>
        </>
    )
}