import { translateLanguage } from "../../translation";
import ReplyIconButton from "./ReplyIconButton";

interface WhatsAppLinkProps {
  phoneNumber: string;
  username: string;
  message: string;
}


const WhatsAppLink = ({ phoneNumber, username, message }:WhatsAppLinkProps) => {
  const finalMessage = translateLanguage("WHATSAPP_MESSAGE")
    .replace("{username}", encodeURIComponent(username))
    .replace("{message}", encodeURIComponent(message));

  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${finalMessage}`;

  return (
    <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
      <ReplyIconButton />
    </a>
  );
};

export default WhatsAppLink
