
import { DataGrid, GridColDef, GridColumnVisibilityModel, GridSortModel } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { UpdateActiveInactiveAPI, UsersListAPI } from '../../services/api_services/users';
import { RootStateType } from '../../store';
import VerifiedIcon from '@mui/icons-material/Verified';
import React from 'react';
import { listRequestInterface } from '../../services/dataType/api_interfaces/users';
import { LightTooltip } from '../common/Tooltip';
import ProfileNameEmailUser from '../common/ProfileNameEmailUser';
import ViewIconButton from '../common/ViewIconButton';
import { ShowErrorAlert, ShowSuccessAlert } from '../../store/alerts';
import DiamondIcon from '@mui/icons-material/Diamond';
import { translateLanguage } from '../../translation';
import { convertToHijri } from '../../utils/convertToHijri';

interface userDatagridProps {
    searchText: string;
    filterType: string;
}

const customLocaleText = {
    noRowsLabel: translateLanguage("NO_USER_FOUND"),
};

export default function UsersDatagrid(props: userDatagridProps) {
    const { searchText, filterType } = props
    console.log(filterType,"filterType");
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [refreshAPI, setRefreshAPI] = useState(0)

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });

    const [sortState, setSortState] = useState<any>({
        field: "",
        sortType: "",
        active: false
    });

    useEffect(() => {
        const apiRequestData: listRequestInterface = {
            pageLimit: paginationModel.pageSize,
            pageNumber: paginationModel.page + 1,
            searchText: searchText,
            filterType: filterType
        }

        if (sortState.active) {
            apiRequestData['sortBy'] = sortState.field
            apiRequestData['sortType'] = sortState.sortType
        }

        UsersListAPI(apiRequestData)
    }, [paginationModel, sortState, searchText, filterType, refreshAPI]);


    const updateActiveStatus = async (id: string, isBlocked: boolean) => {
        const apiRequestData = {
            obj_id: id,
            service_type: "user"
        }
        const response = await UpdateActiveInactiveAPI(apiRequestData)
        if (response) {
            if (!isBlocked) {
                dispatch(ShowSuccessAlert({ visible: true, message: translateLanguage("USER_ACCOUNT_ACTIVATED") }));
            } else {

                dispatch(ShowErrorAlert({ visible: true, message: translateLanguage("USER_ACCOUNT_BLOCKED") }));
            }
            setRefreshAPI(
                refreshAPI + 1
            )
        }
    }

    const typingTimeoutRef = useRef<number | NodeJS.Timeout | undefined>(undefined);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
        id: false,
        wedding_date: !(props?.filterType === 'murfid' || props?.filterType === 'palace'),
        groomProfile: !(props?.filterType === 'murfid' || props?.filterType === 'palace'),
        palaceProfile: !(props?.filterType === 'murfid'),
    });
    
    useEffect(() => {
        setColumnVisibilityModel({
            id: false,
            wedding_date: !(props?.filterType === 'murfid' || props?.filterType === 'palace'),
            groomProfile: !(props?.filterType === 'murfid' || props?.filterType === 'palace'),
            palaceProfile: !(props?.filterType === 'murfid'),
        });
    }, [props?.filterType]);

    const formatDate = (dateString: string) => {
        if (!dateString) {
            return (
                <Box className="pl-12">
                    ---
                </Box>
            );
        }
        return convertToHijri(dateString);
    };

    const generateColumns = (): GridColDef[] => {
        return [
            { field: "id", headerName: "ID", width: 30, flex: 1, },
            {
                field: 'name', headerName: translateLanguage("NAME"), minWidth: 200, flex: 1.2, headerAlign: 'left', sortable: true,
                align: "left",
                renderCell: (params) => {
                    return (
                        <>
                            <ProfileNameEmailUser profileImageAvatar={params.row.photo} profileName={params.row.name} />
                        </>
                    );
                }
            },
            {
                field: 'phoneNumber', headerName: translateLanguage("PHONE"), minWidth: 200, flex: 0.9, sortable: false,
                renderCell: (params) => {
                    return (
                        <>
                            {params.row.countryCode}{" "}{params.row.phoneNumber ? params.row.phoneNumber : "-"}
                            {params.row.phoneVerified &&
                                <VerifiedIcon className='!text-[var(--edit-green)]' sx={{ width: "20px", height: "20px", marginTop: "-2px", marginLeft: "3px", color: "green" }} fontSize="small" />
                            }
                        </>
                    );
                }
            },

            {
                field: 'wedding_date', headerName: translateLanguage("WEDDING_DATE"), minWidth: 200, flex: 0.9, sortable: true,
                renderCell: (params) => {
                    return (
                        <>
                            <Box>
                                {formatDate(params.row.weddingDate)}
                            </Box>
                        </>
                    );
                }
            },
            {
                field: 'city', headerName: translateLanguage("CITY"), minWidth: 120, flex: 0.9, sortable: false,
                renderCell: (params) => {
                    return (
                        <>
                            <Box>
                                {params?.row?.state?.city?.city ? params?.row?.state?.city?.city : '---'}
                            </Box>
                        </>
                    );
                }
            },
            {
                field: 'admin_notes', headerName: translateLanguage("ADMIN_NOTES"), minWidth: 200, flex: 0.9, sortable: false,
                renderCell: (params) => {
                    return (
                        <Box className="w-full max-w-[200px]"> {/* Set a max width for the Box */}
                            <Typography className="block whitespace-nowrap overflow-hidden text-ellipsis">
                                {params.row.admin_notes ? params?.row?.admin_notes : '---'}
                            </Typography>
                        </Box>
                    );
                }
            },
            {
                field: 'groomProfile', headerName: translateLanguage("GROOM"), minWidth: 150, flex: 0.5, sortable: false,
                align: "center",
                headerAlign: 'center',
                renderCell: (params) => {
                    if (params.row.groomProfile === false) {
                        return (
                            <>
                                <Typography variant='h6' className="NunitoSans800 commonStatusRadius !text-center !min-w-[120px] !text-sm !py-2 !rounded !bg-opacity-15  !text-[var(--red)] !border-[var(--red-error-light)] !border-2">
                                    {translateLanguage("UNAVIALABLE")}
                                </Typography>
                            </>
                        );
                    }
                    else {
                        if (params.row.isGroomProfileVerified === false) {
                            return (
                                <Typography variant='h6' className="NunitoSans800 commonStatusRadius !text-center !min-w-[120px] !text-sm !py-2 !rounded !bg-opacity-15  !text-[var(--purple)] !border-[var(--purple-light)] !border-2
                                ">
                                    {translateLanguage("PENDING")}
                                </Typography>
                            );
                        }
                        else {
                            return (
                                <>
                                    <Typography variant='h6' className="NunitoSans800 commonStatusRadius !text-center !min-w-[120px] !text-sm !py-2 !rounded !bg-opacity-15  !text-[var(--edit-green)] !border-[var(--edit-green-light)] !border-2">
                                        <LightTooltip title="Groom profile is created">
                                            <span className='!relative !top-[1px] '>
                                                {translateLanguage("AVIALABLE")}
                                            </span>
                                        </LightTooltip>

                                        {params.row.isGroomProfileTransferred === true && (
                                            <LightTooltip title="User payment received">
                                                <DiamondIcon className='!ml-2 !text-[var(--edit-green)]' sx={{ width: "20px", height: "20px" }} />
                                            </LightTooltip>
                                        )}
                                    </Typography>
                                </>
                            )
                        }
                    }
                }
            },
            {
                field: 'palaceProfile', headerName: translateLanguage("PALACE"), minWidth: 150, flex: 0.5, sortable: false,
                align: 'center',
                headerAlign: 'center',
                renderCell: (params) => {
                    return (
                        <>
                            {params.row.palaceProfile ?
                                <Typography variant='h6' className="NunitoSans800 commonStatusRadius !text-center !min-w-[120px] !text-sm !py-2 !rounded !bg-opacity-15  !text-[var(--edit-green)] !border-[var(--edit-green-light)] !border-2">
                                    {translateLanguage("AVIALABLE")}
                                </Typography>
                                :
                                <Typography variant='h6' className="NunitoSans800 commonStatusRadius !text-center !min-w-[120px] !text-sm !py-2 !rounded !bg-opacity-15  !text-[var(--red)] !border-[var(--red-error-light)] !border-2">
                                    {translateLanguage("UNAVIALABLE")}
                                </Typography>
                            }
                        </>
                    );
                }
            },
            {
                field: 'action', headerName: translateLanguage("ACTION"), minWidth: 200, flex: 0.5, sortable: false,
                headerAlign: 'center',
                renderCell: (params) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='!gap-3 !items-center !flex'>
                            <Link to={`/users/detail/${params?.id}`}>
                                <ViewIconButton />
                            </Link>
                            {
                                !params.row.isBlocked ?
                                    <Button
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            updateActiveStatus(params.row.id, !params.row.isBlocked)
                                        }}

                                        className="NunitoSans800 !px-4 !py-[5px] commonButtonRadius !bg-[var(--active-green)] !text-[var(--white)]  !text-base !font-semibold">
                                        {translateLanguage("ACTIVE")}
                                    </Button>
                                    :
                                    <Button
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            updateActiveStatus(params.row.id, !params.row.isBlocked)
                                        }}
                                        className="NunitoSans800 !px-4 !py-[5px] commonButtonRadius !bg-[var(--red-error)]  !text-[var(--white)]  !text-base !font-semibold">
                                        {translateLanguage("INACTIVE")}
                                    </Button>
                            }
                        </div>
                    );
                }
            },



        ]
    };

    let usersList = useSelector((state: RootStateType) => state.users.usersList);
    let totalUsersCount = useSelector((state: RootStateType) => state.users.totalUsersCount);
    let tableLoader = useSelector((state: RootStateType) => state.alerts.tableLoader);
    const columns = generateColumns();

    useEffect(() => {
        if (totalUsersCount === 0) {
            UsersListAPI({ pageLimit: 10, pageNumber: 1 })
        }
    }, [])

    useEffect(() => {
        clearTimeout(typingTimeoutRef.current);
        typingTimeoutRef.current = setTimeout(() => {
            setPaginationModel({ ...paginationModel, page: 0 });
        }, 1000);
    }, [searchText])


    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        if (sortModel.length > 0) {

            let sortField = ""
            switch (sortModel[0].field) {
                case "phoneNumber":
                    sortField = "phone_no"
                    break;

                default:
                    sortField = sortModel[0].field;
                    break;
            }

            setSortState({
                field: sortField,
                sortType: sortModel[0].sort,
                active: true
            })
        }
        else {
            setSortState({
                field: "",
                sortType: "asc",
                active: false
            })
        }
    }, []);

    return (
        <>
            <div style={{ width: '100%', maxWidth: "2100px" }} className='dataGridTable'>
                <DataGrid
                    sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer'
                        },
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: '#60c4f08f',
                            fontWeight: 1000,
                        },
                        height: "90vh",
                        minHeight: "70vh"

                    }}
                    autoHeight={true}
                    rows={usersList}
                    columns={columns}
                    getRowId={(user) => user.id}
                    rowCount={totalUsersCount}
                    pageSizeOptions={[10, 25, 50]}
                    loading={tableLoader}
                    className='tableHeaderColor commonBorderRadius userDataGridTable commonShadow'
                    localeText={customLocaleText}
                    columnVisibilityModel={columnVisibilityModel}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    onSortModelChange={handleSortModelChange}
                    sortingMode={'server'}
                    onPaginationModelChange={setPaginationModel}
                    disableVirtualization={true}
                    paginationMode={'server'}
                    paginationModel={paginationModel}
                    hideFooterSelectedRowCount={true}
                    disableRowSelectionOnClick={true}
                    onRowClick={(props: any) => {
                        navigate(`/users/detail/${props.id} `, {
                            state: {
                                url: "users",
                                currentPage: paginationModel.page,
                                pageSize: paginationModel.pageSize,
                            }
                        })
                    }}
                />
            </div>
        </>

    );
}





