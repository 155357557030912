
import { DataGrid, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { RootStateType } from '../../store';
import moment from 'moment';
import { listRequestInterface } from '../../services/dataType/api_interfaces/users';
import { PaymentListAPI } from '../../services/api_services/PaymentsListServices';
import ProfileNameEmailUser from '../common/ProfileNameEmailUser';
import { Link, useSearchParams } from 'react-router-dom';
import ViewIconButton from '../common/ViewIconButton';
import { getColorStyles } from '../../pages/Users/UserDetail/UserPaymentDetail';
import { translateLanguage } from '../../translation';
import { convertToHijri } from '../../utils/convertToHijri';

const customLocaleText = {
    noRowsLabel: translateLanguage("NO_PAYMENTS_FOUND"),
};

interface PaymentDatagridInterface {
    filterType: string;
    dialogState: {
        open: boolean;
        startDate: Date;
        endDate: Date,
        triggerApi: boolean
    }
}

const generateColumns = (): GridColDef[] => {
    return [
        { field: "id", headerName: "ID", width: 30, flex: 1, },

        {
            field: 'groomInfo', headerName: translateLanguage("USERS"), maxWidth: 250, minWidth: 200, flex: 1.4, headerAlign: 'left', sortable: false,
            align: "left",
            renderCell: (params) => {
                return (
                    <>
                        <Link to={`/users/detail/${params?.row?.groomInfo?.id}`}>
                            <ProfileNameEmailUser
                                profileImageAvatar={params.row.groomInfo.photo}
                                profileName={params.row.groomInfo.name}
                            />
                        </Link>
                    </>
                );
            }
        },

        {
            field: 'weddingDate', headerName: (<>
                {translateLanguage("WEDDING_DATE")}
            </>
            ) as any, minWidth: 160, flex: 0.9, sortable: false,
            headerAlign: 'left',
            renderCell: (params) => {
                return (
                    <Box >
                        {convertToHijri(params.row.weddingDate)}
                    </Box>
                );
            }
        },

        {
            field: 'paymentType', headerName: translateLanguage("PAYMENT_TYPE"), minWidth: 230, flex: 1, sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Typography variant="body1" style={{ ...getColorStyles(params.row.paymentType) }} className="!px-[12px] !py-[8px] !w-fit  commonStatusRadius text-muted !text-sm textOverflowTwoLines !font-semibold">
                            {(params.row.paymentType === "INDIVIDUAL") ?
                                translateLanguage("INDIVIDUAL")
                                : (params.row.paymentType === "OPPORTUNITY") ?
                                    translateLanguage("OPPORTUNITY") :
                                    params.row.paymentType
                            }
                        </Typography>
                    </>
                );
            }
        },
        {
            field: 'baseAmount', headerName: (<>
                {translateLanguage("BASE_AMOUNT")}
                <span className='!text-xs !text-[#566370] !font-semibold'>
                    (SAR)
                </span>
            </>
            ) as any, minWidth: 170, flex: 0.9, sortable: false,
        },
        {
            field: 'fees', headerName: translateLanguage("FEES"), minWidth: 100, flex: 0.9, sortable: false,
        },

        {
            field: 'totalAmount', headerName: (<>
                {translateLanguage("TOTAL_AMOUNT")}
                <span className="!text-xs !font-semibold !text-[#566370]">
                    (SAR)
                </span>
            </>
            ) as any, minWidth: 180, flex: 0.9, sortable: false,
        },
        {
            field: 'successAt', headerName: translateLanguage("PAID_ON"), minWidth: 130, flex: 0.9, sortable: false,
            headerAlign: 'left',
            renderCell: (params) => {
                return (
                    <Box >
                        {convertToHijri(params.row.successAt)}
                        <br />
                        {moment(params.row.successAt).format('h:mm A')}
                    </Box>
                );
            }
        },
        {
            field: 'action', headerName: translateLanguage("ACTION"), minWidth: 200, flex: 0.5, sortable: false,
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center' }} className='!gap-3 !m-auto !items-center !flex'>
                        <Link to={`/users/detail/user-payment-detail/${params?.id}`}>
                            <ViewIconButton />
                        </Link>
                    </div>
                );
            }
        },
    ]
};

export default function PaymentsListDatagrid(props: PaymentDatagridInterface) {
    const [searchParams, setSearchParams] = useSearchParams();
    const columns = generateColumns();
    let paymentsList = useSelector((state: RootStateType) => state.paymentStore.PaymentList);
    let totalPaymentsCount = useSelector((state: RootStateType) => state.paymentStore.totalPaymentCount);
    let tableLoader = useSelector((state: RootStateType) => state.alerts.tableLoader);

    const [columnVisibilityModel, setColumnVisibilityModel] =
        useState<GridColumnVisibilityModel>({
            id: false
        });

    // const [paginationModel, setPaginationModel] = useState({
    //     pageSize: 10,
    //     page: 0,
    // });
    const page = Number(searchParams.get("page")) || 0;
    const pageSize = Number(searchParams.get("pageSize")) || 10;

      // Handle pagination changes
      const handlePaginationChange = (model: { page: number; pageSize: number }) => {
        searchParams.set("page", model.page.toString());
        searchParams.set("pageSize", model.pageSize.toString());
        setSearchParams(searchParams);
    };

    const apiRequestData: listRequestInterface = {
        pageLimit: pageSize,
        pageNumber: page + 1,
        filterType: props.filterType,
        startDate: props.dialogState.startDate,
        endDate: props.dialogState.endDate
    }
    useEffect(() => {
        PaymentListAPI(apiRequestData)
    }, [pageSize,page, props.filterType])


    useEffect(() => {
        props.dialogState.triggerApi && PaymentListAPI(apiRequestData)
    }, [props.dialogState.triggerApi])

    return (
        <>
            <div style={{ width: '100%', maxWidth: "2100px" }} className='dataGridTableFeaturedService'>
                <DataGrid
                    sx={{
                        '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer'
                        },
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: "#60c4f08f",
                            fontWeight: 1000,
                            fontSize: 16,
                            lineHeight: 1,
                            whiteSpace: "break-spaces",
                            textOverflow: "clip",
                        },
                        height: "90vh",
                        minHeight: "70vh"
                    }}
                    autoHeight={true}
                    rows={paymentsList}
                    className='tableHeaderColor commonBorderRadius commonShadow'
                    columns={columns}
                    getRowId={(user) => user.id}
                    rowCount={totalPaymentsCount}
                    pageSizeOptions={[10, 25, 50]}
                    localeText={customLocaleText}
                    columnVisibilityModel={columnVisibilityModel}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    rowHeight={85}
                    loading={tableLoader}
                    disableVirtualization={true}
                    paginationMode={'server'}
                    paginationModel={{ page, pageSize }}
                    onPaginationModelChange={handlePaginationChange}
                    hideFooterSelectedRowCount={true}
                    disableRowSelectionOnClick={true}
                />
            </div>

        </>
    );
}
