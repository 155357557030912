
import * as React from 'react';
import { DataGrid, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { Box} from '@mui/material';
import { useEffect, useState } from 'react';
import { RootStateType } from '../../store';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import { listRequestInterface } from '../../services/dataType/api_interfaces/users';
import { FeaturedServicesListAPI } from '../../services/api_services/featuredServices';
import ProfileNameEmailUser from '../common/ProfileNameEmailUser';
import ViewIconButton from '../common/ViewIconButton';
import { Link, useSearchParams } from 'react-router-dom';
import { translateLanguage } from '../../translation';
import { convertToHijri } from '../../utils/convertToHijri';

const customLocaleText = {
    noRowsLabel: translateLanguage("NO_FEATURED_SERVICE_FOUND"),
};

interface featuredServicesInterface {
    filterType: string;
    paginationReset: boolean;
    setPaginationReset: (value: boolean) => void;
}

export default function FeaturedServicesDatagrid(props: featuredServicesInterface) {
    const { filterType } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const generateColumns = (): GridColDef[] => {
        return [
            {
                field: "id", headerName: "ID", width: 30, flex: 1,
            },

            {
                field: 'service_owner', headerName: translateLanguage("SERVICE_OWNER"), minWidth: 230, flex: 1, sortable: false,
                renderCell: (params) => {
                    return (
                        <>
                            <ProfileNameEmailUser
                                profileImageAvatar={params.row.donorInfo.photo} profileName={params.row.donorInfo.name} />
                        </>
                    );
                }
            },

            {
                field: 'groomInfo', headerName: translateLanguage("GROOM"), minWidth: 230, flex: 1, sortable: false,
                renderCell: (params) => {
                    return (
                        <>
                            <ProfileNameEmailUser
                                profileImageAvatar={params.row.requestedBy.photo} profileName={params.row.requestedBy.name} />
                        </>
                    );
                }
            },
            {
                field: 'amount', headerName: translateLanguage("AMOUNT"), minWidth: 110, flex: 0.9, sortable: false,
            },
            {
                field: 'wedding_date', headerName:
                    (
                        <>
                            {translateLanguage("WEDDING_DATE")}
                        </>
                    ) as any, minWidth: 180, flex: 0.5, sortable: false,
                valueGetter: (params) => parseFloat(params.row.requestedBy.weddingDate),

                renderCell: (params) => {
                    return (
                        <Box>
                            {convertToHijri(params.row.requestedBy.weddingDate)}
                        </Box>
                    );
                }
            },
            {
                field: 'created_at', headerName: translateLanguage("CREATED_AT"), minWidth: 200, flex: 0.9, sortable: false,
                renderCell: (params) => {
                    return (
                        <Box>
                            {convertToHijri(params.row.createdAt)}
                            <br />
                            {moment(params.row.createdAt).format('h:mm A')}
                        </Box>
                    );
                }
            },
            {
                field: 'action', headerName: translateLanguage("ACTION"), minWidth: 200, flex: 0.5, sortable: false,
                headerAlign: 'center',
                renderCell: (params) => {

                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }} className='!gap-3 !m-auto !items-center !flex'>
                            <Link to={`/users/detail/user-payment-detail/${params.row.payment?.id}`}>
                                <ViewIconButton />
                            </Link>
                        </div>
                    );
                }
            },

        ]
    };

    const columns = generateColumns();
    const [iconColors, setIconColors] = useState<{ [key: string]: 'gray' | 'green' }>({});
    const [guestNumbersModalsOpen, setGuestNumbersModalsOpen] = React.useState(false);
    const [selectedGuestNumbers, setSelectedGuestNumbers] = React.useState<string>('');
    let featuredServices = useSelector((state: RootStateType) => state.featuredServices.featuredServicesList);
    let totalFeatureServicesCount = useSelector((state: RootStateType) => state.featuredServices.totalFeaturedServicesCount);
    let tableLoader = useSelector((state: RootStateType) => state.alerts.tableLoader);

    const copyToClipboardGuestNumbers = (text: string, id: string) => {
        navigator.clipboard.writeText(text);
        setIconColors(prev => ({ ...prev, [id]: 'green' }));
        setTimeout(() => {
            setIconColors(prev => ({ ...prev, [id]: 'gray' }));
        }, 2000);
    };

    const guestModalsClickOpen = (guestNumbers: string, id: string) => {
        setSelectedGuestNumbers(guestNumbers);
        setGuestNumbersModalsOpen(true);
    };

    const guestModalClose = () => {
        setGuestNumbersModalsOpen(false);
    };

    const [columnVisibilityModel, setColumnVisibilityModel] =
        useState<GridColumnVisibilityModel>({
            id: false
        });

    // const [paginationModel, setPaginationModel] = useState({
    //     pageSize: 10,
    //     page: 0,
    // });

    const page = Number(searchParams.get("page")) || 0;
    const pageSize = Number(searchParams.get("pageSize")) || 10;

     // Handle pagination changes
     const handlePaginationChange = (model: { page: number; pageSize: number }) => {
        searchParams.set("page", model.page.toString());
        searchParams.set("pageSize", model.pageSize.toString());
        setSearchParams(searchParams);
    };

    useEffect(() => {
        const apiRequestData: listRequestInterface = {
            pageLimit: pageSize,
            pageNumber: page + 1,
            filterType: filterType
        }
        FeaturedServicesListAPI(apiRequestData)
    }, [pageSize, page, filterType])

    useEffect(() => {
        if (props?.paginationReset) {
            searchParams.set("page", "0");  // Reset page to 0
            searchParams.set("pageSize", pageSize.toString());
            setSearchParams(searchParams);
            props?.setPaginationReset(false); // Reset the flag after applying
        }
    }, [props?.paginationReset]);

    return (
        <>
            <div style={{ width: '100%', maxWidth: "2100px" }} className='dataGridTableFeaturedService'>
                <DataGrid
                    sx={{
                        '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer'
                        },
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: "#60c4f08f",
                            fontWeight: 1000,
                            fontSize: 18,
                        },
                        height: "90vh",
                        minHeight: "70vh"
                    }}
                    autoHeight={true}
                    className='tableHeaderColor commonBorderRadius commonShadow'
                    rows={featuredServices}
                    columns={columns}
                    getRowId={(user) => user.id}
                    rowCount={totalFeatureServicesCount}
                    pageSizeOptions={[10, 25, 50]}
                    localeText={customLocaleText}
                    columnVisibilityModel={columnVisibilityModel}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    rowHeight={85}
                    loading={tableLoader}
                    disableVirtualization={true}
                    paginationMode={'server'}
                    paginationModel={{ page, pageSize }}
                    onPaginationModelChange={handlePaginationChange}
                    hideFooterSelectedRowCount={true}
                    disableRowSelectionOnClick={true}
                />
            </div>
            <React.Fragment>
                <Dialog
                    open={guestNumbersModalsOpen}
                    onClose={guestModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className='featuredServiceDiologBox'
                >
                    <DialogTitle id="alert-dialog-title" className="!font-semibold">
                        {translateLanguage("GUEST_NUMBERS")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText className='!font-semibold' id="alert-dialog-description">
                            {selectedGuestNumbers}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button style={{ border: '2px solid gray' }} className='!text-sm !mb-4 !mr-4 !font-semibold !px-6 !py-2 !text-gray-800 !bg-opacity-10 !bg-gray-600 !border-2 !border-gray-600' onClick={guestModalClose}>
                            {translateLanguage("CLOSE")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </>
    );
}
