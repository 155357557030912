import { Box, Button, FormControl, FormLabel, MenuItem, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import DatePicker from '../../components/common/DatePicker';
import PaymentsListDatagrid from '../../components/Datagrids/PaymentsListDatagrid';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../store';
import CommonHeaderText from '../../components/common/CommonHeaderText';
import { translateLanguage } from '../../translation';

export default function PaymentList() {
    const navigate = useNavigate();
    const location = useLocation();
    // Retrieve filterType and date range from URL params if available
    const urlParams = new URLSearchParams(location.search);
    const initialFilterType = urlParams.get('filterType') || "-";
    const initialStartDate = urlParams.get('startDate') 
        ? moment(urlParams.get('startDate'), 'YYYY-MM-DD').toDate() 
        : new Date(Date.now() - (30 * 24 * 60 * 60 * 1000)); // 30 days ago by default
    const initialEndDate = urlParams.get('endDate') 
        ? moment(urlParams.get('endDate'), 'YYYY-MM-DD').toDate() 
        : new Date(); // Current date by default

    const [filterType, setFilterType] = useState(initialFilterType)

    const handleFilterChange = (event: SelectChangeEvent<string>) => {
        const newFilterType = event.target.value;
        setFilterType(newFilterType);

        // Update URL with the new filter type
        const params = new URLSearchParams(location.search);
        params.set('filterType', newFilterType);
        navigate({ search: params.toString() });
    }

    const [dialogState, setDialogState] = useState({
        open: false,
        startDate: initialStartDate,
        endDate: initialEndDate,
        triggerApi: false
    })


    let paymentsList = useSelector((state: RootStateType) => state.paymentStore.PaymentList);
    useEffect(() => {
        setDialogState({
            ...dialogState,
            triggerApi: false
        })
    }, [paymentsList])

    return (
        <>
            <DatePicker dialogState={dialogState} setDialogState={setDialogState} navigate={navigate} location={location} />
            <div className="!py-5 !px-5">
                <div className='!mb-5 !gap-5 lg:!flex !items-centeer !justify-between ' >
                    <Box>
                        <CommonHeaderText headerName={translateLanguage("PAYMENTS")} />
                    </Box>
                    <Box className="!flex !items-center !gap-2 selecterFocus" >
                        <FormControl>
                            <FormLabel className='!text-[var(--gray-font)] !font-semibold NunitoSans800'>{translateLanguage("PAYMENT_TYPE")}</FormLabel>
                            <Select
                                style={{ background: "var(--white)" }}

                                MenuProps={{
                                    sx: {
                                        "&& .Mui-selected": {
                                            color: "var(--primary)",
                                            background: "var(--primary-light) ",
                                        },
                                    },
                                }}
                                sx={{
                                    "& [aria-expanded=true]": {
                                        background: "var(--white)",
                                    },
                                }}


                                labelId="filter-type-label"
                                id="filter-type-select"
                                className='commonButtonRadius commonShadow NunitoSans800 filterType'
                                value={filterType}
                                onChange={handleFilterChange}
                            >
                                <MenuItem className='NunitoSans600' value="-">{translateLanguage("ALL")}</MenuItem>
                                <MenuItem className='NunitoSans600' value="individual">{translateLanguage("INDIVIDUAL")}</MenuItem>
                                <MenuItem className='NunitoSans600' value="opportunity">{translateLanguage("OPPORTUNITY")}</MenuItem>
                            </Select>


                        </FormControl>
                        <Box>
                            <Typography variant="body1" className='!text-[var(--gray-font)] NunitoSans800 !font-semibold' style={{
                                color: 'rgba(0, 0, 0, 0.54)'
                            }}>
                                {translateLanguage("DATE_RANGE")}
                            </Typography>

                            <Button
                                variant="outlined"
                                style={{ background: "var(--white)" }}
                                className='commonButtonRadius commonShadow NunitoSans800 primaryButtonClass'
                                type="button"
                                onClick={() => setDialogState({ ...dialogState, open: true })}
                            >
                                {moment(dialogState.startDate).format('MMMM D, YYYY')}-
                                {moment(dialogState.endDate).format('MMMM D, YYYY')}

                            </Button>
                        </Box>
                    </Box>


                </div>
                <PaymentsListDatagrid filterType={filterType} dialogState={dialogState} />
            </div >
        </>

    )
}
