import MenuItem from '@mui/material/MenuItem';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Sidebar, Menu, MenuItem as ProMenuItem, MenuItemStyles, menuClasses } from "react-pro-sidebar";
import { Box, Button, FormControl, IconButton, Select, SelectChangeEvent } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import AppLogo from "../../static/AppLogo";
import { logoutApi } from "../../services/api_services/authentication";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "../../store";
import LogoSidebar from "../../components/icons/LogoSidebar";
import ContactEmergencyOutlinedIcon from "@mui/icons-material/ContactEmergencyOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { UpdateCurrentLanguage } from "../../store/alerts";
import TapAndPlayOutlinedIcon from "@mui/icons-material/TapAndPlayOutlined";
import TabOutlinedIcon from "@mui/icons-material/TabOutlined";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import SwitchAccessShortcutAddIcon from '@mui/icons-material/SwitchAccessShortcutAdd';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import PushPinSharpIcon from '@mui/icons-material/PushPinSharp';
import { translateLanguage } from '../../translation';

const themes: any = {
  sidebar: {
    backgroundColor: "#ffffff",
    color: "#607489",
  },
  menu: {
    menuContent: "#fbfcfd",
    hover: {
      backgroundColor: "var(--primary)",
      fontWeight: 600,
      color: "var(--primary)",
    },
    disabled: {
      color: "#9fb6cf",
    },
  },
};

const SidebarLayout = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { pathname } = location;

  const [collapsed, setCollapsed] = useState(false);
  const [broken, setBroken] = useState(false);
  const [toggled, setToggled] = useState(false);

  // hex to rgba converter
  const hexToRgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "16px",
      fontWeight: 400,
    },
    icon: {
      color: themes.menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes.menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(themes.menu.menuContent, !collapsed ? 0.4 : 1)
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: "themes.menu.disabled.color",
      },
      [`&.${menuClasses.active}`]: {
        borderRadius: "10px",
        color: themes.menu.hover.color,
        backgroundColor: hexToRgba(themes.menu.hover.backgroundColor, 1),
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  function handleStore() {
    //     navigate('/');
    return "";
  }

  const logoutUser = async () => {
    const logoutSuccess = await logoutApi();
    logoutSuccess && navigate("/");
  };

  let adminInfo = useSelector((state: RootStateType) => state.adminInfo);

  const token = adminInfo ? adminInfo.accessToken : "";

  const currentLanguage = useSelector((state: RootStateType) => state.alerts.currentLanguage)

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    dispatch(UpdateCurrentLanguage(event.target.value))
  }

  return (
    <>
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        breakPoint="md"
        className='commonShadow !border-[#efefefc7]'
        onBreakPoint={setBroken}
        backgroundColor={hexToRgba(themes.sidebar.backgroundColor, 1)}
        rootStyles={{
          color: themes.sidebar.color,
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "99vh", }}
        >
          <div style={{ flex: 1, marginBottom: "32px" }}>
            {!broken && (
              <div style={{ display: "flex", justifyContent: "flex-end" }} className="!z-10 !bg-white !sticky !top-0 !shadow">
                <IconButton
                  className="menu-icon !right-5 !relative"
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <MenuIcon className="!font-semibold" />
                </IconButton>
              </div>
            )}
            <Box className="!flex !items-baseline !mt-[10px] !gap-5 !shadow" >
              <Menu className="!pointer-events-none">
                <ProMenuItem icon={<AppLogo />}>
                  <span className="!text-lg !pl-5 NunitoSans500 !text-[var(--black)] !font-semibold">
                    Refdah Admin
                  </span>
                </ProMenuItem>
              </Menu>
            </Box>

            <Menu menuItemStyles={menuItemStyles} className="selected-menu-items">
              <ProMenuItem
                component={<Link to="/dashboard" />}
                icon={<DashboardOutlinedIcon />}
                onClick={() => {
                  setToggled(!toggled);
                }}
                active={/^\/dashboard\b/.test(pathname)}
              >
                {" "}
                {translateLanguage("DASHBOARD")}
              </ProMenuItem>

              <ProMenuItem
                component={<Link to="/users" />}
                icon={<PeopleOutlineIcon />}
                onClick={() => {
                  setToggled(!toggled);
                }}
                active={/^\/users\b/.test(pathname)}
              >
                {" "}
                {translateLanguage("USERS")}
              </ProMenuItem>

              <ProMenuItem
                active={/^\/opportunity\b/.test(pathname)}
                component={<Link to="/opportunity" />}
                onClick={() => {
                  setToggled(!toggled);
                }}
                icon={
                  <PushPinSharpIcon style={{ transform: "rotate(40deg)" }} className="!text-[22px] md:!text-[27px] " />

                }
              >
                {" "}
                {translateLanguage("OPPORTUNITIES")}
              </ProMenuItem>

              <ProMenuItem
                active={/^\/pending-cards\b/.test(pathname)}
                component={<Link to="/pending-cards" />}
                onClick={() => {
                  setToggled(!toggled);
                }}
                icon={<ContactEmergencyOutlinedIcon />}
              >
                {" "}
                {translateLanguage("CARD_REQUEST")}
              </ProMenuItem>

              <ProMenuItem
                active={/^\/custom-card\b/.test(pathname)}
                component={<Link to="/custom-card" />}
                icon={<TabOutlinedIcon />}
                onClick={() => {
                  setToggled(!toggled);
                }}
              >
                {translateLanguage("MENU_CARD")}
              </ProMenuItem>

              <ProMenuItem
                active={/^\/toast\b/.test(pathname)}
                component={<Link to="/toast" />}
                onClick={() => {
                  setToggled(!toggled);
                }}
                icon={<TapAndPlayOutlinedIcon />}
              >
                {translateLanguage("BANNER_NOTIFICATION")}
              </ProMenuItem>

              <ProMenuItem
                active={/^\/featured-services\b/.test(pathname)}
                component={<Link to="/featured-services" />}
                onClick={() => {
                  setToggled(!toggled);
                }}
                icon={<SwitchAccessShortcutAddIcon />}
              >
                {translateLanguage("FEATURED_SERVICES")}
              </ProMenuItem>

              <ProMenuItem
                active={/^\/payments\b/.test(pathname)}
                component={<Link to="/payments" />}
                onClick={() => {
                  setToggled(!toggled);
                }}
                icon={<AccountBalanceOutlinedIcon />}
              >
                {translateLanguage("PAYMENTS")}
              </ProMenuItem>



              <ProMenuItem
                active={/^\/state-city\b/.test(pathname)}
                component={<Link to="/state-city" />}
                onClick={() => {
                  setToggled(!toggled);
                }}
                icon={<LocationOnOutlinedIcon />}
              >
                {" "}
                {translateLanguage("STATES")}
              </ProMenuItem>

              <ProMenuItem
                active={/^\/relation\b/.test(pathname)}
                component={<Link to="/relation" />}
                onClick={() => {
                  setToggled(!toggled);
                }}
                icon={<Diversity3OutlinedIcon />}
              >
                {" "}
                {translateLanguage("RELATION")}
              </ProMenuItem>

              <ProMenuItem
                active={/^\/feedback\b/.test(pathname)}
                component={<Link to="/feedback" />}
                onClick={() => {
                  setToggled(!toggled);
                }}
                icon={<ThumbUpAltIcon />}
              >
                {" "}
                {translateLanguage("FEEDBACK")}
              </ProMenuItem>
             
            </Menu>
          </div>

          {
            ((/^\/state-city\b/.test(pathname)) ||
              (/^\/relation\b/.test(pathname)))
            &&
            <FormControl>
              <Select labelId="filter-type-label" id="filter-type-select" className='filterType NunitoSans700' value={currentLanguage}
                onChange={handleLanguageChange}>
                <MenuItem className='NunitoSans600' value="en">{!collapsed ? "English" : "EN"} </MenuItem>
                <MenuItem className='NunitoSans600' value="ar">{!collapsed ? "Arabic" : "AR"} </MenuItem>
              </Select>
            </FormControl>
          }

          <Button
            sx={{
              fontWeight: 600,
              mb: "35px",
              padding: "12px",
              borderRadius: "10",
            }}
            className='!bg-[var(--primary)] !flex NunitoSans800 !gap-2 !text-white commonButtonRadius !m-4'
            onClick={logoutUser}
          >
            <LogoutIcon />
            {!collapsed && translateLanguage("LOGOUT")}
          </Button>
        </div>
      </Sidebar>

      {broken && (
        <Box className="menu-icon !flex !gap-3 !items-center  !bg-white !w-full !fixed commonShadow !py-[6px] !px-3">
          <IconButton
            className="menu-icon"
            onClick={() => {
              setToggled(!toggled);
              setCollapsed(false);
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box className="!flex !items-center !gap-2 !mt-1">
            <LogoSidebar className="!max-w-[30px]  !max-h-[30px]" />
            <span
              style={{
                fontWeight: 600,
                fontSize: "15px",
              }}
            >
              Refdah Admin
            </span>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SidebarLayout;
