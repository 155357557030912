import { postRequest } from "../../utils/services";
import { FEEDBACK_LIST } from "../../constants";
import { store } from "../../store";
import { updateFeedbackList } from "../../store/feedback/actions";
import { updateTotalFilteredCount, updateTotalNotesCount, updateTotalSuggestionCount, updateTotalComplaintCount, updateTotalContactusCount } from "../../store/feedback/actions";
import { FeedbackInterface, feedbackRequest, FeedbackRequestInterface } from "../dataType/api_interfaces/feedback";

export const getFeedbackList = async (values: FeedbackRequestInterface) => {
    console.log(values,"values");
    const { dispatch } = store;

    let data = feedbackRequest({
        ...values,
        sortBy: values.sortBy || 'created_at',
        sortType: values.sortType || 'desc'
    });
    
    try {
        const response = await postRequest(FEEDBACK_LIST, data);
        if (response && response.data) {
            // const feedbackList: FeedbackInterface[] = response.data;
            const feedbackList: FeedbackInterface[] = response.data.feedback_list;
            const totalFilteredCount = response.data.filtered_count;
            const totalNotesCount = response.data.total_notes;
            const totalSuggestionCount = response.data.total_suggestion;
            const totalContactusCount = response.data.total_contact_us;
            const totalComplaintCount = response.data.total_complaint;
            dispatch(updateFeedbackList(feedbackList));
            dispatch(updateTotalFilteredCount(totalFilteredCount || 0));
            dispatch(updateTotalNotesCount(totalNotesCount || 0));
            dispatch(updateTotalSuggestionCount(totalSuggestionCount || 0));
            dispatch(updateTotalContactusCount(totalContactusCount || 0));
            dispatch(updateTotalComplaintCount(totalComplaintCount || 0));
        }

    } catch (error) {
        console.error("Error fetching feedback list:", error);
    }
};



