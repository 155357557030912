import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { CustomMenuCardListAPI } from "../../services/api_services/customMenuCards";
import { CustomCardRow } from "./Card.row";
import AddCardMenu from "../../components/Modals/AddCardMenu";
import { CardData } from "../../services/dataType/api_interfaces/state_city";
import AddPrimaryButton from "../../components/common/AddPrimaryButton";
import CommonHeaderText from "../../components/common/CommonHeaderText";
import { translateLanguage } from "../../translation";

interface ModalState {
  open: boolean;
  cardData?: CardData;
}

const CustomCardsList = () => {
  const [addCardModal, setAddCardModal] = useState<ModalState>({
    open: false,
  });

  const [cardsList, setCardsList] = useState<CardData[]>([]);

  const fetchCards = () => {
    CustomMenuCardListAPI(setCardsList);
  };

  useEffect(() => {
    fetchCards();
  }, []);

  const showEditCardModal = (cardData: CardData) => {
    setAddCardModal({ open: true, cardData });
  };

  return (
    <>
      <Box className="p-5">
        <div
          className="!flex !items-center !h-full !mb-[5px]"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "10px",
          }}
        >
          <Box>
            <CommonHeaderText headerName={translateLanguage("MENU_CARD")} />
          </Box>

          <AddPrimaryButton
            onClick={() => {
              setAddCardModal({ open: true });
            }}
            buttonName={translateLanguage("ADD_CARD")}
            variant="outlined"
          />
        </div>

        {cardsList.length > 0 &&
          cardsList.map((cardObj, i) => (
            <CustomCardRow
              cardObj={cardObj}
              key={i}
              onEdit={() => showEditCardModal(cardObj)}
            />
          ))}

        <AddCardMenu
          addCardModal={addCardModal}
          setAddCardModal={setAddCardModal}
          fetchCards={fetchCards}
        />
      </Box>
    </>
  );
};

export default CustomCardsList;
