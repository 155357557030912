import { Avatar, Box } from '@mui/material';

const ProfileNameEmailUser = (props?: any) => {
  const { profileImageAvatar, profileName, ProfileEmail, profilePhoneNumber, className, style } = props;
  return (
    <Box>
      <Box className={`!items-center !flex !gap-[2px] !px-[6px] !w-fit ${className}`}
        style={style}
        >
        <Avatar  sx={{ minWidth: 40, width: 40, height: 40, marginRight: 1, border: '1px solid #8d989a47' }}
          alt="Remy Sharp" src={profileImageAvatar || "https://i.pinimg.com/736x/c0/27/be/c027bec07c2dc08b9df60921dfd539bd.jpg"} />
        <Box>
          <Box className='textOverflowLowLength NunitoSans700' >
            {profileName}
          </Box>
          <Box className='textOverflow' style={{ color: 'rgba(0, 0, 0, 0.4)' }}>
            {ProfileEmail}
          </Box>
          <Box className='textOverflow' style={{ color: 'rgba(0, 0, 0, 0.4)' }}>
            {profilePhoneNumber}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ProfileNameEmailUser