import { Button } from '@mui/material';
import AddIcon from "@mui/icons-material/AddOutlined";

const AddPrimaryButton = (props?: any) => {
    const { className, style, variant, onClick, buttonName } = props;
    return (
            <Button
                onClick={onClick}
                style={style}
                className={` !items-center !gap-2 !flex NunitoSans900 !py-2  commonButtonRadius !bg-[var(--primary)] !text-[var(--white)] !font-semibold ${className}`}
                variant={variant}
            >
               {buttonName} <AddIcon />
            </Button>
    )
}

export default AddPrimaryButton