import { Box, IconButton } from '@mui/material';
import WhatsApp from "@mui/icons-material/WhatsApp";

const ReplyIconButton = (props?: any) => {
    const { className, style } = props;
    return (
        <Box>
            <IconButton className={` !flex !min-w-[60px] !capitalize !whitespace-nowrap !gap- !font-semibold !items-center !px-3 !text-[14px] !rounded-md !bg-[var(--active-green)] !text-white ${className}`} style={style}>
                <WhatsApp
                    style={{ cursor: "pointer" }}
                    className="!w-4 !h-4"
                />
            </IconButton>
        </Box>
    )
}

export default ReplyIconButton