import { TextField, IconButton, Slide, Fade } from '@mui/material'
import ClearIcon from '@mui/icons-material/ClearOutlined';
import React from 'react'
import { translateLanguage } from '../../translation';

export default function SearchBox(props: any) {
    const { searchText, setSearchText } = props

    const handleClearClick = () => {
        setSearchText('')
    }

    function searchChange(text: React.ChangeEvent<HTMLInputElement>) {
        setSearchText(text.target.value)
    }

    return (
        <div className='!flex searchbarWidth'>
            <Slide
                direction="down"
                in={true}
                className='searchBox '>
                <TextField
                    InputProps={{
                        style: {
                            height: '40px',
                            marginTop: "10px",
                            borderRadius: "5px",
                        },
                        endAdornment: (
                            <IconButton
                                onClick={handleClearClick}
                            >
                                <ClearIcon />
                            </IconButton>
                        ),
                    }}
                    value={searchText}
                    sx={{ marginTop: "-10px" }}
                    onChange={searchChange} id="search" placeholder={translateLanguage("SEARCH")} variant="outlined" />
            </Slide>
        </div>
    )
}
