import { stateCityListResponseInterface } from '../../services/dataType/api_interfaces/state_city';
import { AddCitytAPI, AddNewStateAPI, SaveCityAPI, SaveStateAPI, StateCityListAPI } from '../../services/api_services/state_city';
import TextDialogBox from '../../components/common/TextDialogBox';
import { RootStateType } from '../../store';
import TruncatedText from '../../components/common/TruncatedText';
import { ShowErrorAlert, ShowSuccessAlert } from '../../store/alerts';
import classNames from 'classnames';
import { Button, EditLocationAlt, IconButton, ModeEdit, Paper, Stack, TextField, Typography, useDispatch, useEffect, useRef, useSelector, useState } from '../../utils/commonImports';
import AddPrimaryButton from '../../components/common/AddPrimaryButton';
import { UpdateActiveInactiveAPI } from '../../services/api_services/users';
import { LightTooltip } from '../../components/common/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { translateLanguage } from '../../translation';


const StateCityList = () => {
    const dispatch = useDispatch();
    const [selectedState, setSelectedState] = useState<any>(null);
    const currentLanguage = useSelector((state: RootStateType) => state.alerts.currentLanguage)
    const stateTitleRef: any = useRef(null);
    const cityTitleRef: any = useRef(null);

    const [refreshAPI, setRefreshAPI] = useState(0)

    const [dialogState, setDialogState] = useState<any>({
        open: false,
        modeType: "",
        formType: "",
        existingValue: "",
        onSubmitCallback: () => { }
    })

    const [editState, setEditState] = useState({
        id: "",
        editting: false,
    })

    const [editCity, setEditCity] = useState({
        id: "",
        editting: false,
    })

    const [states, setStates] = useState<stateCityListResponseInterface[]>([]);

    const getStatesCityList = async () => {
        const res = await StateCityListAPI(setStates)
        if (res) {
            return true;
        }
    }

    useEffect(() => {
        getStatesCityList()
        setEditState({
            id: "",
            editting: false,
        })
        setEditCity({
            id: "",
            editting: false,
        })
    }, [currentLanguage, refreshAPI])

    useEffect(() => {
        setSelectedState(selectedState && states.filter(state => state.id === selectedState.id)[0]);
    }, [states])


    useEffect(() => {
        if (states.length > 0 && !selectedState) {
            setSelectedState(states[0]);
        }
    }, [states])

    const handleStateClick = (state: any) => {
        setSelectedState(state);
    };

    const handleStateEdit = (stateObj: any) => {
        stateTitleRef.current = stateObj.state;
        setEditState({
            id: stateObj.id,
            editting: true
        })
    }

    const successCallbackStateSave = (stateData: any, modeType: string) => {
        if (modeType === "edit") {
            setStates(prevStates => prevStates.map(state => {
                if (state.id === stateData.id) {
                    return { ...state, state: stateData.state };
                }
                return state;
            }))
            setEditState({
                id: "",
                editting: false
            })
        }
        else {
            const tempState = [
                {
                    id: stateData.id, state: stateData.state,
                    is_active: stateData.is_active, is_allowed: stateData.is_allowed,
                    cities: []
                }]
            setStates(prevStates => [...tempState, ...prevStates]);
        }
    }

    const handleStateSave = () => {
        if (!stateTitleRef.current.value && !stateTitleRef.current.value.trim()) {
            dispatch(ShowErrorAlert({ visible: true, message: translateLanguage("STATE_REQUIRED") }));
            return false;
        } else if (stateTitleRef.current.value.length > 40) {
            dispatch(ShowErrorAlert({ visible: true, message: translateLanguage("STATE_CHAR_LIMIT") }));
            return false;
        }

        const requestData = {
            state: stateTitleRef.current.value,
            state_id: editState.id
        }
        SaveStateAPI(requestData, successCallbackStateSave)
    }

    const AddNewStateCallbackSave = async (data: any) => {
        const response = await AddNewStateAPI(data.title, getStatesCityList)
        if (response) {
            setDialogState({
                ...dialogState,
                open: false
            })
        }
    }

    const successCallbackCitySave = (apiData: any, modeType: string) => {
        setStates(prevStates =>
            prevStates.map(state => {
                if (state.id === apiData.state) {
                    return {
                        ...state,
                        cities: state.cities.map(city => {
                            if (city.id === apiData.id) {
                                return {
                                    ...city,
                                    city: apiData.city
                                };
                            }
                            return city;
                        })
                    };
                }
                return state;
            })
        );

        setEditCity({
            id: "",
            editting: false
        })
    }

    const AddNewCityCallbackSave = async (data: any) => {
        const requestData = {
            city: data.title,
            state_id: selectedState.id
        }

        const response = await AddCitytAPI(requestData, getStatesCityList)
        if (response) {
            setDialogState({
                ...dialogState,
                open: false
            })
        }
    }

    const saveCityEditAPI = () => {

        if (!cityTitleRef.current.value && !cityTitleRef.current.value.trim()) {
            dispatch(ShowErrorAlert({ visible: true, message: translateLanguage("CITY_REQUIRED") }));
            return false;
        } else if (cityTitleRef.current.value.length > 40) {
            dispatch(ShowErrorAlert({ visible: true, message: translateLanguage("CITY_CHAR_LIMIT") }));
            return false;
        }

        const requestData = {
            city: cityTitleRef.current.value,
            city_id: editCity.id
        }

        SaveCityAPI(requestData, successCallbackCitySave)
    }

    const cityEditInitiated = (cityObj: any) => {
        cityTitleRef.current = cityObj.city;
        setEditCity({
            id: cityObj.id,
            editting: true
        })
    }


    const updateActiveStatus = async (id: string, isBlocked: boolean, service_type: string) => {
        const apiRequestData = {
            obj_id: id,
            service_type: service_type
        }
        const response = await UpdateActiveInactiveAPI(apiRequestData)
        if (response) {
            if (!isBlocked) {
                dispatch(ShowSuccessAlert({
                    visible: true, message: `${service_type === "state" ?
                        translateLanguage("STATE")
                        :
                        translateLanguage("CITY")

                        }  
                        ${translateLanguage("ACTIVATED")}`
                }));
            } else {
                dispatch(ShowErrorAlert({
                    visible: true, message: `${service_type === "state" ?
                        translateLanguage("STATE")
                        :
                        translateLanguage("CITY")
                        } ${translateLanguage("DISABLED")}`
                }));
            }
            setRefreshAPI(
                refreshAPI + 1
            )
        }
    }

    return (
        <div
            className='!overflow-x-auto !px-5 !min-w-[1000px]'
            style={{ display: 'flex', padding: '30px 0px', height: '98vh', justifyContent: "space-between" }}>
            <TextDialogBox dialogState={dialogState} setDialogState={setDialogState} />
            <Paper className="commonBorderRadius commonShadow"
                elevation={5}
                style={{
                    width: '55%',
                    overflowY: 'auto',
                }}
            >
                <Stack
                    className='!p-1  commonButtonRadius commonShadowLight'
                    direction="row"
                    sx={{
                        marginBottom: '20px',
                        justifyContent: "space-between",
                        alignItems: "center",
                        position: "sticky",
                        top: 0,
                        backgroundColor: "white",
                        zIndex: 1,
                        height: "auto"
                    }}>
                    <Typography
                        variant="h6"
                        className='!font-semibold NunitoSans800 !pl-3'
                        style={{ textAlign: "center" }}>

                        {translateLanguage("STATE")}
                    </Typography>

                    <AddPrimaryButton
                        onClick={() => setDialogState({
                            open: true,
                            modeType: translateLanguage("ADD"),
                            formType: translateLanguage("STATE"),
                            title_length: "40",
                            existingValue: "",
                            onSubmitCallback: AddNewStateCallbackSave
                        })}
                        buttonName={translateLanguage("ADD_STATE")}
                        variant="outlined"
                    />
                </Stack >

                <Stack direction="column" className='!px-5 !pb-3' spacing={1}>
                    {states.map((stateObj, index) => (
                        <div
                            className={classNames('!gap-3 listingShadow  commonShadowLight !mb-2 !p-1', {
                                '!border-2 !border-[var(--purple-light)]': index === 0 && !selectedState,
                                '!border-2 addingClass !border-[var(--purple-light)]': selectedState && selectedState.id === stateObj.id,
                            })}
                            key={index}
                            style={{
                                display: "flex",
                                background: 'white',
                                boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 7px 1px",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}>
                            {
                                (editState.editting && editState.id === stateObj.id)
                                    ?
                                    <>
                                        <Button
                                            color="error"
                                            variant="contained"
                                            className='!px-4 !py-[8px] !text-sm !font-semibold NunitoSans800 commonButtonRadius !bg-red-500 !text-white'
                                            onClick={() => {
                                                setEditState({
                                                    id: "",
                                                    editting: false
                                                })
                                            }}
                                        >
                                            {translateLanguage("CANCEL")}
                                        </Button>
                                        <TextField
                                            inputRef={stateTitleRef}
                                            defaultValue={stateTitleRef.current}
                                            className='!w-full'
                                            InputProps={{
                                                style: {
                                                    height: '40px',
                                                },
                                            }}
                                        />
                                        <Button
                                            variant="contained"
                                            className='!px-4 !py-[8px] !text-sm !font-semibold commonButtonRadius NunitoSans800 !bg-green-500 !text-white'
                                            onClick={() => handleStateSave()}
                                        >
                                            {translateLanguage("SAVE")}
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Typography className='pl-3 NunitoSans500'
                                            sx={{
                                                backgroundColor: 'inherit',
                                                color: "black"
                                            }}
                                        >
                                            <TruncatedText text={stateObj.state} maxLength={30} />
                                        </Typography>

                                        <Button
                                            variant={stateObj.is_allowed ? "contained" : "outlined"}
                                            disabled={!stateObj.is_allowed}
                                            className={`NunitoSans800 !ml-auto !px-4 !py-[5px] commonButtonRadius !text-base !font-semibold
                                            ${stateObj.is_allowed ?
                                                    (stateObj.is_active ?
                                                        '!bg-[var(--active-green)] !text-[var(--white)]'
                                                        :
                                                        ' !bg-[var(--red-error)]  !text-[var(--white)] '
                                                    )
                                                    : ''}
                                            `}
                                            onClick={() => updateActiveStatus(
                                                stateObj.id, stateObj.is_active,
                                                "state"
                                            )}

                                        >
                                            {stateObj.is_active ? translateLanguage("ACTIVE") : translateLanguage("INACTIVE")}
                                        </Button>

                                        {!stateObj.is_allowed && (
                                            <LightTooltip title="Can't disable state in use">
                                                <InfoIcon />
                                            </LightTooltip>
                                        )}


                                        <IconButton
                                            onClick={() => handleStateEdit(stateObj)}
                                            className="!px-3 !py-[5px] !text-sm  commonButtonRadius NunitoSans800  !bg-opacity-15 !font-semibold !bg-[var(--edit-green-light)] !text-[var(--edit-green)]"
                                        >
                                            <ModeEdit className='!w-4 !mr-[5px] !text-[var(--edit-green)]' />

                                            {translateLanguage("EDIT")}
                                        </IconButton>
                                        <Button
                                            onClick={() => handleStateClick(stateObj)}
                                            className="!px-3 !py-[5px] commonButtonRadius NunitoSans800  !bg-opacity-15 !text-[var(--purple)] !bg-[var(--purple-light)] !text-sm !font-semibold">
                                            <EditLocationAlt className='!w-4 !mr-[6px]' />
                                            {translateLanguage("CITY")}
                                        </Button>
                                    </>
                            }
                        </div>
                    ))}
                </Stack >
            </Paper >

            {
                selectedState && (
                    <Paper className="commonBorderRadius commonShadow" style={{
                        width: '40%',
                        overflowY: 'auto',
                    }}>
                        <Stack className='!p-1 commonButtonRadius commonShadowLight ' direction="row" sx={{
                            marginBottom: '20px',
                            justifyContent: "space-between",
                            alignItems: "center",
                            position: "sticky",
                            top: 0,
                            backgroundColor: "white",
                            zIndex: 1,
                            height: "auto"
                        }}>
                            <Typography variant="h6" className='pl-3 NunitoSans800' style={{ textAlign: "center" }}>
                                <strong>
                                    {translateLanguage("CITIES")}: &nbsp;
                                </strong>
                                <span className="!text-base !text-gray-500 NunitoSans600">
                                    {selectedState.state}
                                </span>
                            </Typography>

                            <AddPrimaryButton
                                onClick={() => setDialogState({
                                    open: true,
                                    modeType: translateLanguage("ADD"),
                                    formType: translateLanguage("CITY"),
                                    title_length: "40",
                                    existingValue: "",
                                    onSubmitCallback: AddNewCityCallbackSave
                                })}
                                buttonName={translateLanguage("ADD_CITY")}
                                variant="outlined"
                            />
                        </Stack>

                        <Stack direction="column" spacing={1} className='!px-5 !pb-3'>
                            {selectedState.cities.map((cityObj: any, index: number) => (
                                <div
                                    className='!gap-3  commonShadowLight !mb-2 !p-1'
                                    key={index}
                                    style={{
                                        display: "flex",
                                        background: 'white',
                                        boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 7px 1px",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                    {
                                        (editCity.editting && editCity.id === cityObj.id)
                                            ?
                                            <>
                                                <Button
                                                    color="error"
                                                    className='!px-4 !py-[8px] !text-sm !font-semibold commonButtonRadius NunitoSans800 !bg-red-500 !text-white'
                                                    onClick={() => {
                                                        setEditCity({
                                                            id: "",
                                                            editting: false
                                                        })
                                                    }}
                                                >
                                                    {translateLanguage("CANCEL")}
                                                </Button>
                                                <TextField
                                                    inputRef={cityTitleRef}
                                                    className='!w-full'
                                                    defaultValue={cityTitleRef.current}
                                                    InputProps={{
                                                        style: {
                                                            height: '40px',
                                                        },
                                                    }}
                                                />
                                                <Button
                                                    onClick={() => saveCityEditAPI()}
                                                    className='!px-4 !py-[8px] !text-sm !font-semibold commonButtonRadius NunitoSans800 !bg-green-500 !text-white'
                                                >
                                                    {translateLanguage("SAVE")}
                                                </Button>
                                            </>
                                            :
                                            <>
                                                < Typography
                                                    className='pl-3 NunitoSans500'
                                                    sx={{
                                                        backgroundColor: 'inherit',
                                                        color: "black"
                                                    }}
                                                >
                                                    <TruncatedText text={cityObj.city} maxLength={30} />
                                                </Typography>
                                                <Button
                                                    variant={cityObj.is_allowed ? "contained" : "outlined"}
                                                    disabled={!cityObj.is_allowed}
                                                    className={`NunitoSans800 !ml-auto !px-4 !py-[5px] commonButtonRadius !text-base !font-semibold
                                                            ${cityObj.is_allowed ?
                                                            (cityObj.is_active ?
                                                                '!bg-[var(--active-green)] !text-[var(--white)]'
                                                                :
                                                                ' !bg-[var(--red-error)]  !text-[var(--white)] '
                                                            )
                                                            : ''}
                                                            `}
                                                    onClick={() => updateActiveStatus(
                                                        cityObj.id, cityObj.is_active,
                                                        "city"
                                                    )}
                                                >
                                                    {cityObj.is_active ? translateLanguage("ACTIVE") : translateLanguage("INACTIVE")}
                                                </Button>
                                                {!cityObj.is_allowed && (
                                                    <LightTooltip title="Can't disable city in use">
                                                        <InfoIcon />
                                                    </LightTooltip>
                                                )}

                                                <IconButton
                                                    onClick={() => cityEditInitiated(cityObj)}

                                                    className="!px-3 !py-[5px] !text-sm  commonButtonRadius NunitoSans800 !bg-opacity-15 !font-semibold !bg-[var(--edit-green-light)] !text-[var(--edit-green)]"
                                                >
                                                    <ModeEdit className='!w-4 !mr-[5px] !text-[var(--edit-green)]' />
                                                    {translateLanguage("EDIT")}
                                                </IconButton>
                                            </>
                                    }
                                </div>
                            ))}
                        </Stack>
                    </Paper >
                )
            }
        </div >
    );
};

export default StateCityList;
