import React, { useEffect, useState, useRef } from 'react';
import { DataGrid, GridColDef, GridColumnVisibilityModel, GridSortModel } from '@mui/x-data-grid';
import { RootStateType } from '../../store';
import { useSelector } from 'react-redux';
import { FeedbackRequestInterface } from '../../services/dataType/api_interfaces/feedback';
import { getFeedbackList } from '../../services/api_services/feedback';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import ViewIconButton from '../common/ViewIconButton';
import { translateLanguage } from '../../translation';
import WhatsAppLink from '../common/WhatsAppLink';
import moment from 'moment';
import { convertToHijri } from '../../utils/convertToHijri';
import { useSearchParams } from 'react-router-dom';

const formatDate = (dateString: string) => {
    return moment(dateString).format("YYYY-MM-DD"); // Convert to readable date format
};

interface FeedbackGridProps {
    searchText?: string;
    filterType?: string;
    paginationReset: boolean;
    setPaginationReset: (value: boolean) => void;
}

const customLocaleText = {
    noRowsLabel: translateLanguage("NO_DATA_FOUND"),
};

export default function FeedbackDatagrid(props: FeedbackGridProps) {
    const { searchText, filterType } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const generateColumns = (): GridColDef[] => {
        return [
            {
                field: 'first_name', headerName: translateLanguage("USER_NAME"), minWidth: 200, maxWidth: 200, flex: 1.4, headerAlign: 'left', sortable: false,
                align: "left",
                renderCell: (params) => {
                    return (
                        <>
                            {params.row.first_name}
                        </>
                    );
                }
            },
            {
                field: 'phone_no', headerName: translateLanguage("PHONE"), minWidth: 200, maxWidth: 200, flex: 1.4, headerAlign: 'left', sortable: false,
                align: "left",
                renderCell: (params) => {
                    return (
                        <>
                            {params.row.country_code}&nbsp;&nbsp;
                            {params.row.phone_no}
                        </>
                    );
                }
            },
            {
                field: 'message', headerName: translateLanguage("MESSAGE"), minWidth: 200, flex: 1.4, headerAlign: 'left', sortable: false,
                align: "left",
                renderCell: (params) => {
                    return (
                        <>
                            <Typography className="!whitespace-nowrap !max-w-[800px] !overflow-hidden !text-ellipsis">
                                {params.row.message}
                            </Typography>
                        </>
                    );
                }
            },
            {
                field: 'created_at', headerName: translateLanguage("CREATED_AT"), minWidth: 200, flex: 1.4, headerAlign: 'left', sortable: true,
                align: "left",
                renderCell: (params) => {
                    return (
                        <Typography>
                            {convertToHijri(params.row.created_at)}
                        </Typography>
                    );
                }
            },
            {
                field: 'action', headerName: translateLanguage("ACTION"), minWidth: 200, flex: 0.5, sortable: false,
                headerAlign: 'left',
                renderCell: (params) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }} className='!gap-3 !items-center'>
                           <WhatsAppLink phoneNumber={params.row.country_code+params.row.phone_no} username={params.row.first_name} message={params.row.message}/>
                            <div onClick={() => handleViewButtonClick(params.row)} className='!m-auto !items-center !justify-center '>
                                <ViewIconButton />
                            </div>
                        </div>)
                }
            },

        ]
    }


    const columns = generateColumns();
    const typingTimeoutRef = useRef<number | NodeJS.Timeout | undefined>(undefined);
    const [columnVisibilityModel] =
        useState<GridColumnVisibilityModel>({
            id: true
        });
    const page = Number(searchParams.get("page")) || 0;
    const pageSize = Number(searchParams.get("pageSize")) || 10;

    // Get sort values from URL or use defaults
    const sortBy = searchParams.get("sortBy") || "";
    const sortType = searchParams.get("sortType") || "";

    const [sortState, setSortState] = useState<any>(() => {
        return {
            field: sortBy,
            sortType: sortType || "asc", // Default to "asc" if not set
            active: !!sortBy
        };
    });
    const [selectedFeedback, setSelectedFeedback] = useState<any>(null);
    const [openModal, setOpenModal] = useState(false);
    const feedbackList = useSelector((state: RootStateType) => state?.feedback?.feedbackList);
    let tableLoader = useSelector((state: RootStateType) => state.alerts.tableLoader);
    const totalFilteredCount = useSelector((state: RootStateType) => state?.feedback?.totalFilteredCount);

     // Handle pagination changes
     const handlePaginationChange = (model: { page: number; pageSize: number }) => {
        searchParams.set("page", model.page.toString());
        searchParams.set("pageSize", model.pageSize.toString());
        setSearchParams(searchParams);
    };

    useEffect(() => {
        const apiRequestData: FeedbackRequestInterface = {
            pageLimit: pageSize,
            pageNumber: page + 1,
            searchText: searchText,
            filterType: filterType
        }

        if (sortState.active) {
            apiRequestData['sortBy'] = sortState.field
            apiRequestData['sortType'] = sortState.sortType
        }

        getFeedbackList(apiRequestData)
    }, [page, pageSize, sortState, searchText, filterType]);


    // useEffect(() => {
    //     clearTimeout(typingTimeoutRef.current);
    //     typingTimeoutRef.current = setTimeout(() => {
    //         setPaginationModel({ ...paginationModel, page: 0 });
    //     }, 1000);
    // }, [searchText])

    useEffect(() => {
        if (props?.paginationReset) {
            searchParams.set("page", "0");  // Reset page to 0
            searchParams.set("pageSize", pageSize.toString());
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.delete("sortBy");
            newSearchParams.delete("sortType");
            setSearchParams(newSearchParams);
            setSortState({ field: "", sortType: "asc", active: false }); // Reset state sorting
            props?.setPaginationReset(false); // Reset the flag after applying
        }
    }, [props?.paginationReset]);


    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            const sortField = sortModel[0].field;
            const sortDirection = sortModel[0].sort || "asc";

            // Update URL parameters
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set("sortBy", sortField);
            newSearchParams.set("sortType", sortDirection);
            setSearchParams(newSearchParams);

            setSortState({
                field: sortField,
                sortType: sortDirection,
                active: true
            });
        } else {
            searchParams.delete("sortBy");
            searchParams.delete("sortType");
            setSearchParams(searchParams);

            setSortState({
                field: "",
                sortType: "asc",
                active: false
            });
        }
    }, []);


    const handleViewButtonClick = (feedback: any) => {
        setSelectedFeedback(feedback);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <div style={{ width: '100%', maxWidth: "2100px" }} className='dataGridTable feedbackDataGridTwoLineHeight'>
            <DataGrid
                autoHeight={true}
                rows={feedbackList}
                columns={columns}
                loading={tableLoader}
                getRowId={(row) => row.id}
                pageSizeOptions={[10, 25, 50]}
                className='feedbackDatagrid tableHeaderColor commonBorderRadius commonShadow'
                localeText={customLocaleText}
                paginationMode={'server'}
                paginationModel={{ page, pageSize }}
                columnVisibilityModel={columnVisibilityModel}
                onSortModelChange={handleSortModelChange}
                sortingMode={'server'}
                onPaginationModelChange={handlePaginationChange}
                rowCount={totalFilteredCount}
            />

            <Dialog open={openModal} maxWidth="sm" fullWidth onClose={handleCloseModal} className='!p-10'>
                <DialogTitle className='NunitoSans800' style={{ fontWeight: 600, fontSize: '1.3rem' }}>
                    {translateLanguage("FEEDBACK_MESSAGE")}
                </DialogTitle>
                <DialogContent className='NunitoSans800 !text-[var(--gray-font)]'>{selectedFeedback && selectedFeedback.message}</DialogContent>
                <DialogActions className=" !pb-7">
                    <Button onClick={handleCloseModal} type="button" variant="contained" className='!border-2 NunitoSans800 !mr-4 commonButtonRadius !bg-[var(--cancel-gray)] !px-6 !py-[8px] !font-semibold  !text-base '>
                        {translateLanguage("CLOSE")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
