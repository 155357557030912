import React, { useContext, useRef, useState } from "react";
import { Button, Modal, IconButton } from '@mui/material';
import { Typography, Box } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PushPinSharpIcon from '@mui/icons-material/PushPinSharp';
import "./GroomInfo.css";
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel} from "@material-tailwind/react";
import PalacePostTable from './PalacePostTable';
import ForwardIcon from '@mui/icons-material/Forward';
import { SentTransferMoneyApi } from "../../../services/api_services/users";
import { ContextInterface, MakePaymentRequest } from "../../../services/dataType/api_interfaces/users";
import { ShowErrorAlert } from "../../../store/alerts";
import { useDispatch } from "react-redux";
import { UserDataContext } from "./UserDetail";
import PalacePaymentTable from "./PalacePaymentTable";
import ProfileNameEmailUser from "../../../components/common/ProfileNameEmailUser";
import { useParams } from "react-router-dom";
import { translateLanguage } from "../../../translation";

export default function PalaceInfo(props: any) {
    const dispatch = useDispatch();
    const { userId } = useParams();

    const contextValue: ContextInterface = useContext(UserDataContext);

    const { userData } = props;
    const [openModal, setOpenModal] = useState(false);
    const [AmountInput, setAmountInput] = useState<number>(0);
    const remarksText = useRef<HTMLInputElement>(null)

    const [selectedValue, setSelectedValue] = useState("");

    const userFields = [
        { label: translateLanguage("WEDDING_HALL_NAME"), value: userData.weddingHallName },
        { label: translateLanguage("PLACE_NAME"), value: userData.placeName },
    ];

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setSelectedValue("")
        setAmountInput(0)
        setOpenModal(false);
    };


    const handleTransferMoney = async () => {
        if (!AmountInput || AmountInput === 0) {
            dispatch(ShowErrorAlert({ visible: true, message: translateLanguage("AMOUNT_REQURIED") }));
            return false
        }

        const amountRecivedOnPost = userData.opportunityList.filter((opportunityObj: { id: string; }) => {
            return opportunityObj.id === selectedValue
        })[0].total_payments_recieved_on_post
        const amountGivenToPalace = userData.opportunityList.filter((opportunityObj: { id: string; }) => {
            return opportunityObj.id === selectedValue
        })[0].total_amount_given_to_palace


        if ((amountRecivedOnPost - amountGivenToPalace < AmountInput)) {
            dispatch(ShowErrorAlert({ visible: true, message: translateLanguage("AMOUNT_CANNOT_GREATER_OPP_BALANCE") }));
            return false
        }

        const requestData: MakePaymentRequest = {
            user_id: userId,
            payment_to: "palace",
            amount_transfer: (Number(AmountInput)).toString(),
            opportunity_id: selectedValue,
            remarks: remarksText.current?.value?.trim() ?? ""
        };
        try {
            const successHitPaymentAPI = await SentTransferMoneyApi(requestData);
            if (successHitPaymentAPI) {

                handleCloseModal();
                contextValue.setUserData({
                    ...contextValue.userData,
                    loading: true
                });
            }
        } catch (error) {
            console.error("Error transferring money:", error);
        }
    };

    return (
        <>
            <Box className="!py-5 commonBorderRadius !bg-white px-5 commonShadow !gap-4">
                <Box
                    sx={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.04)" }}
                    className="lg:!flex !justify-between !border commonBorderRadius  !bg-white"
                >
                    <Box className="!p-5 lg:!flex !grid !items-center !justify-between !w-full">
                        <Box className="lg:!border-l-2 lg:!pl-3">
                            {userFields.map((field, index) => (
                                <Box key={index} className="xl:!flex !grid xl:!gap-10 !gap-1">
                                    <Typography variant="subtitle1" className="!min-w-[180px] NunitoSans600 text-[var(--gray-font)] xl:!mt-0 !mt-3 ">
                                        {field.label}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        className="!text-muted !pt-1 NunitoSans700 textOverflowTwoLines !font-semibold"
                                    >
                                        {field.value}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
                <Box className="!py-5 ">
                    <Tabs value="payment">
                        <Box className="lpxl:!flex !overflow-x-auto !items-center !grid !gap-4 !justify-between !w-full ">
                            <TabsHeader className="!max-w-[500px] sm:!min-w-[400px] !min-w-[290px]">
                                {[
                                    { label: translateLanguage("TRANSFERRED"), value: "payment", icon: AccountBalanceIcon },
                                    { label: translateLanguage("OPPORTUNITIES"), value: "post", icon: PushPinSharpIcon },

                                ].map(({ label, value, icon }) => (
                                    <Tab key={value} value={value}>
                                        <div className={`flex items-center gap-2 NunitoSans700`}>
                                            {React.createElement(icon, { className: `!w-[19px] !h-[19px] ${value === 'post' ? 'iconRotate' : ''}` })}
                                            {label}
                                        </div>
                                    </Tab>
                                ))}
                            </TabsHeader>

                            <Box className="lg:!flex !grid !mb-5 !items-center !px-5 lg:!px-0 !gap-4 " style={{ gridTemplateColumns: "1fr " }}>
                                <Typography variant="h3" sx={{
                                    background: "var(--green-transfered-light)",
                                }} className="!font-semibold NunitoSans800 !text-[var(--green-transfered)]  lg:!ml-1 lg:!w-fit !w-full !text-sm md:!text-base !whitespace-nowrap   commonButtonRadius  sm:!px-5 !px-2 !py-[6px]">

                                    {translateLanguage("TRANSFERRED")}:&nbsp;
                                    <span className="!font-semibold NunitoSans800 !text-base !text-[var(--green-transfered)] md:!text-lg ">
                                        {
                                            userData?.opportunityList.reduce(
                                                (total: number, obj: any) => total + obj.total_amount_given_to_palace, 0)
                                        }

                                    </span>
                                </Typography>

                                <Typography variant="h3" sx={{
                                    background: "var(--yellow-dark-light)",
                                }} className="!font-semibold NunitoSans800 lg:!ml-1 !text-sm !text-[var(--yellow-dark)] md:!text-base !whitespace-nowrap commonButtonRadius  sm:!px-5 !px-2 !py-[6px]">
                                    {translateLanguage("TOTAL")}:&nbsp;
                                    <span className="!font-semibold NunitoSans800 !text-base !text-[var(--yellow-dark)] md:!text-lg ">
                                        {
                                            userData?.opportunityList.reduce(
                                                (total: number, obj: any) => total + obj.total_payments_recieved_on_post, 0)
                                        }
                                    </span>
                                </Typography>

                                <Button variant="contained" onClick={handleOpenModal} color="success" className="!font-semibold NunitoSans800  commonButtonRadius !min-w-[130px] sm:!px-8 !px-4 !flex !gap-2 !py-2 !text-sm md:!text-base !whitespace-nowrap !bg-[var(--primary)]">Transfer <ForwardIcon /> </Button>
                            </Box>
                        </Box>
                        <TabsBody>
                            {[
                                {
                                    label: translateLanguage("PAYMENTS"),
                                    value: "payment",
                                    components: (
                                        <PalacePaymentTable />
                                    ),
                                },
                                {
                                    label: translateLanguage("POST"),
                                    value: "post",
                                    components: (
                                        <PalacePostTable
                                        />
                                    ),
                                },
                            ].map(({ value, components }) => (
                                <TabPanel key={value} value={value} className="!overflow-x-auto !px-0">
                                    {components}
                                </TabPanel>
                            ))}
                        </TabsBody>

                    </Tabs>

                </Box>
            </Box>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box className="!rounded-md !overflow-auto !max-h-[700px] !pt-5 !pb-2 !px-5 md:!px-8" sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', maxWidth: 700, bgcolor: 'background.paper', boxShadow: 24, }}>
                    <Typography id="modal-title" variant="h6" component="h2" className="!font-semibold NunitoSans800 !flex !justify-between !items-center">
                        {translateLanguage("TRANSFER_TO_PALACE")}
                        <IconButton onClick={handleCloseModal}>
                            <ClearIcon />
                        </IconButton>
                    </Typography>

                    <Typography variant="h6" className="!text-base !mb-1 NunitoSans600 !mt-4">
                        {translateLanguage("SELECT_OPPORTUNTIY_CREATED_FOR_GROOM")}
                    </Typography>
                    <Select
                        fullWidth
                        className="selecterTransferMoneyPlace"
                        value={selectedValue}
                        onChange={(e) => setSelectedValue(e.target.value)}
                        variant="outlined"
                    >
                        {
                            userData.opportunityList.map((opportunityObj: any) => {
                                return (
                                    <MenuItem value={opportunityObj.id} className="">
                                        <ProfileNameEmailUser profileImageAvatar={opportunityObj.groom.photo} profileName={opportunityObj.groom.name} />
                                    </MenuItem>)
                            })
                        }
                    </Select>

                    <Box className="!grid !gap-3 !w-full !items-center !mt-4">
                        <Typography variant="h4" sx={{
                            background: "var(--yellow-dark-light)",
                        }} className="!text-[var(--yellow-dark)] !w-full !h-fit !font-semibold !whitespace-nowrap !text-sm sm:!text-base  commonButtonRadius !px-3 lg:!px-5 !py-[14px]">
                            <span className="!flex !gap-1 NunitoSans800 !items-center !text-[var(--yellow-dark)]">
                                {translateLanguage("AMOUNT_GROOM_RECIEVED")}:
                                <span className="!font-semibold !text-base NunitoSans800 sm:!text-lg !text-[var(--yellow-dark)]">
                                    {
                                        selectedValue ?
                                            userData.opportunityList.filter((opportunityObj: { id: string; }) => {
                                                return opportunityObj.id === selectedValue
                                            })[0].total_payments_recieved_to_groom
                                            : 0
                                    }
                                </span>
                            </span>
                        </Typography>

                        <Typography variant="h4" sx={{
                            background: "var(--lightblue-balance-light)",
                        }} className=" !w-full !h-fit !font-semibold !whitespace-nowrap !text-sm sm:!text-base commonButtonRadius !px-3 lg:!px-5 !py-[14px]">

                            <span className="!flex !gap-1 NunitoSans800 !items-center !text-[var(--lightblue-balance)] ">
                                {translateLanguage("AMOUNT_OPPORTUNTIY_RECIEVED")}:
                                <span className="!font-semibold !text-base NunitoSans800 sm:!text-lg !text-[var(--lightblue-balance)] ">
                                    {
                                        selectedValue ?
                                            userData.opportunityList.filter((opportunityObj: { id: string; }) => {
                                                return opportunityObj.id === selectedValue
                                            })[0].total_payments_recieved_on_post
                                            : 0
                                    }
                                </span>
                            </span>
                        </Typography>
                        <Typography variant="h4" sx={{
                            background: "var(--green-transfered-light)",
                        }} className="!text-[var(--green-transfered)] !w-full !h-fit !font-semibold !whitespace-nowrap !text-sm sm:!text-base  commonButtonRadius !px-3 lg:!px-5 !py-[14px]">
                            <span className="!flex !gap-1 NunitoSans800 !items-center !text-[var(--green-transfered)]">
                                {translateLanguage("AMOUNT_TRANSFERRED_TO_GROOM")} :
                                <span className="!font-semibold !text-base NunitoSans800 sm:!text-lg !text-[var(--green-transfered)]">
                                    {
                                        selectedValue ?
                                            userData.opportunityList.filter((opportunityObj: { id: string; }) => {
                                                return opportunityObj.id === selectedValue
                                            })[0].total_amount_given_to_groom
                                            : 0
                                    }
                                </span>
                            </span>
                        </Typography>

                        <Typography variant="h4" sx={{
                            background: "var(--red-error-light)",
                        }} className="commonButtonRadius !w-full !h-fit !font-semibold !whitespace-nowrap !text-sm sm:!text-base   !px-3 lg:!px-5 !py-[14px]">
                            <span className="!flex !gap-1 NunitoSans800 !text-[var(--red-dark)] !items-center">
                                {translateLanguage("AMOUNT_TRANSFERRED_TO_PALACE")}:
                                <span className="!font-semibold NunitoSans800 !text-[var(--red-dark)] !text-base sm:!text-lg ">
                                    {

                                        selectedValue ?
                                            userData.opportunityList.filter((opportunityObj: { id: string; }) => {
                                                return opportunityObj.id === selectedValue
                                            })[0].total_amount_given_to_palace
                                            : 0
                                    }
                                </span>
                            </span>
                        </Typography>
                    </Box>



                    <Box className="!mt-3 !gap-3 !items-center !justify-between !flex">
                        <TextField
                            fullWidth
                            value={AmountInput}
                            type="number"
                            placeholder={translateLanguage("ENTER_AMOUNT_HERE")}
                            onChange={(e: any) => (setAmountInput(e.target.value))}
                        />
                        <TextField
                            fullWidth
                            placeholder={translateLanguage("ENTER_REMARKS_HERE")}
                            title="Remarks"
                            type="text"
                            inputRef={remarksText}
                        />
                    </Box>

                    <Box className="!mb-4 !mt-5" display="flex" justifyContent="center">
                        <Button
                            onClick={handleTransferMoney}
                            className="!font-semibold !px-8 !flex !gap-2 commonButtonRadius NunitoSans800 !py-[10px] !text-base"
                            variant={selectedValue ? "contained" : "outlined"}
                            sx={{ background: selectedValue ? "#06990ef5" : "#c2c2c240" }}
                            disabled={!selectedValue}
                            color="success">
                            {translateLanguage("TRANSFER")}
                            <ForwardIcon />
                        </Button>
                    </Box>
                </Box>
            </Modal>

        </>
    )
}
