import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Button, FormControl, FormLabel, MenuItem, Select, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../../store';
import { ContextInterface } from '../../../services/dataType/api_interfaces/users';
import { UserDataContext } from './UserDetail';
import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import ProfileNameEmailUser from '../../../components/common/ProfileNameEmailUser';
import { translateLanguage } from '../../../translation';
import { convertToHijri } from '../../../utils/convertToHijri';

interface ReportDataProps {
    sortedPayments: any
}

const customLocaleText = {
    noRowsLabel: translateLanguage("NO_DATA_FOUND"),
};

const generateColumns = (): GridColDef[] => {
    return [
        {
            field: 'name', headerName: translateLanguage("USERS"), minWidth: 300, flex: 1.2, headerAlign: 'left', sortable: true,
            align: "left",
            renderCell: (params) => {
                return (
                    <>
                        <ProfileNameEmailUser profileImageAvatar={params.row.donorInfo.photo} profileName={params.row.donorInfo.name} />
                    </>
                );
            }
        },
        {
            field: 'date', headerName: translateLanguage("DATE"), minWidth: 180, flex: 0.9, sortable: true,
            renderCell: (params) => {
                return (
                    <>
                        {convertToHijri(params.row.time)} {" "}
                        {moment(params.row.time).format('HH:mm')}
                    </>
                );
            }
        },
        {
            field: 'baseAmount', headerName: translateLanguage("AMOUNT"), minWidth: 180, flex: 0.9, sortable: true,
        },
    ]
};


const extractedUserInfo = (user: any) => {
    const { name, email, countryCode, phoneNumber } = user.userBasicInfo;
    const { weddingHallName, weddingDate, } = user.groomBasicInfo;
    const completePhone = `${countryCode}-${phoneNumber}`
    const formatedWeddingDate = moment(weddingDate).format('YYYY-MM-DD')

    return [
        name, email, completePhone, weddingHallName, formatedWeddingDate
    ];
}

const extractedLeaderInfo = (obj: any) => {
    const { leaderName, leaderEmail, leaderCountryCode, leaderPhone, opportunityAddedOn } = obj;
    const completePhone = `${leaderCountryCode}-${leaderPhone}`
    const formatedOpportunityAddedDate = moment(opportunityAddedOn).format('YYYY-MM-DD')

    return [
        leaderName, leaderEmail, completePhone, formatedOpportunityAddedDate
    ];
}



function Report({ sortedPayments }: ReportDataProps) {
    const contextValue: ContextInterface = useContext(UserDataContext);
    const paymentsData: any = contextValue.userData.data.groomPaymentsInfo;
    const groomInfo: any = contextValue.userData.data;


    const csvHeaders = ['Name', 'Email', 'Phone number', 'Wedding Hall', 'Wedding Date', 'Sender', 'Amount'];

    const [opportunityPaymentReportCSV, setOpportunityPaymentReport] = useState([csvHeaders])
    const [individualPaymentReportCSV, setIndividualPaymentReport] = useState([csvHeaders])
    const [opportunityList, setOpportunityList] = useState<any[]>([])
    const [selectedOpportunity, setSelectedOpportunity] = useState("")

    const generateIndividualCsv = () => {
        const filtered_payments = paymentsData.filter((payment: { paymentType: string; }) =>
            payment.paymentType === "INDIVIDUAL")

        if (filtered_payments.length > 0) {

            const groomInfoArray = [
                ...extractedUserInfo(groomInfo),
                filtered_payments[0].donorInfo.isDonorAnonymous ? "Anonymous" : filtered_payments[0].donorInfo.name,
                filtered_payments[0].baseAmount
            ];

            const mapRemainingPayments = filtered_payments.map((payment: any) =>
                ["", "", "", "", "",
                    payment.donorInfo.isDonorAnonymous ? "Anonymous" : payment.donorInfo.name,
                    payment.baseAmount]).splice(1)

            const finalArray = [
                csvHeaders,
                groomInfoArray,
                ...mapRemainingPayments
            ]
            setIndividualPaymentReport(finalArray)
        }
    }


    const generateOpportunityCsv = () => {
        const filtered_payments = paymentsData.filter(
            (payment: {
                opportunity_info: any; paymentType: string;
            }) =>
                payment.paymentType === "OPPORTUNITY"
                && payment.opportunity_info.opportunityId == selectedOpportunity
        )

        if (filtered_payments.length > 0) {
            const groomInfoArray = [
                ...extractedUserInfo(groomInfo),

                filtered_payments[0].donorInfo.isDonorAnonymous ? "Anonymous" : filtered_payments[0].donorInfo.name,
                filtered_payments[0].baseAmount,
                ...extractedLeaderInfo(filtered_payments[0].opportunity_info)
            ];


            const mapRemainingPayments = filtered_payments.map((payment: any) =>
                ["", "", "", "", "",
                    payment.donorInfo.isDonorAnonymous ? "Anonymous" : payment.donorInfo.name,
                    payment.baseAmount]).splice(1)

            const finalArray = [
                [...csvHeaders, 'Leader Name', 'Leader Email', 'Leader Phone', 'Opportunity created on'],
                groomInfoArray,
                ...mapRemainingPayments
            ]
            setOpportunityPaymentReport(finalArray)
        }
    }
    useEffect(() => {
        generateOpportunityCsv()
    }, [selectedOpportunity])

    const generateOpportunity = () => {
        const filtered_payments = paymentsData.filter((payment: { paymentType: string; }) =>
            payment.paymentType === "OPPORTUNITY")
        const tempArray: any[] = []
        filtered_payments.forEach((payment: any) => {
            const { opportunityId } = payment.opportunity_info;
            if (!tempArray.some((item) => item.opportunityId === opportunityId)) {
                tempArray.push(payment.opportunity_info);
            }
        });
        setOpportunityList(tempArray)
    }

    useEffect(() => {
        generateIndividualCsv()
        generateOpportunity()
    }, [])

    const [value, setValue] = React.useState(0);
    const columns = generateColumns();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    let tableLoader = useSelector((state: RootStateType) => state.alerts.tableLoader);


    return (
        <>
            <Box className="!p-5">
                <Box className="!px-4 !pt-2 !pb-10 !border commonShadow commonBorderRadius " sx={{
                    width: '100%', bgcolor: 'background.paper',
                    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.06)"
                }}
                >
                    <Tabs className='reportParentTab' value={value} onChange={handleChange}>
                        <Tab className='reportTabs lg:!min-w-[130px]' label={translateLanguage("INDIVIDUAL")} />
                        <Tab className='reportTabs lg:!min-w-[130px]' label={translateLanguage("OPPORTUNITIES")} />
                    </Tabs>


                    <Box className="!mt-6">
                        {value === 0 && (
                            <Box className="!mt-6">
                                <Box className="!flex !items-baseline !justify-between">
                                    <Typography variant='h6' className="!text-base NunitoSans600 !text-black">
                                        {translateLanguage("INDIVIDUAL_DETAILS")}
                                    </Typography>
                                    <Button
                                        style={{ border: "1px solid " }}
                                        className='!font-semibold NunitoSans800 !text-base  !rounded !bg-opacity-15 !bg-blue-500 !text-blue-500 !py-2 !border-2 !border-blue-500 !px-3'>
                                        <FileDownloadIcon className='mr-2' />
                                        <CSVLink data={individualPaymentReportCSV} filename={"GroomPaymentReport.csv"}>
                                            {translateLanguage("DOWNLOAD_REPORT")}
                                        </CSVLink>
                                    </Button>
                                </Box>

                                <Box style={{ width: '100%', maxWidth: "2100px" }} className='dataGridTable oppurtunitiesGridTable !mt-3'>
                                    <DataGrid
                                        rows={paymentsData.filter((payment: { paymentType: string; }) => payment.paymentType === "INDIVIDUAL")}
                                        columns={columns}
                                        autoHeight={true}
                                        loading={tableLoader}
                                        getRowId={(row) => row.id}
                                        pageSizeOptions={[10, 25, 50]}
                                        className='feedbackDatagrid tableHeaderColor commonBorderRadius commonShadow'
                                        localeText={customLocaleText}
                                        paginationMode={'server'}
                                    />
                                </Box>
                            </Box>
                        )}



                        {value === 1 && (
                            <>
                                <Box className="!mt-6">
                                    <Box className="!flex !items-baseline selecterFocus !justify-between">
                                        <FormControl>
                                            <FormLabel className='NunitoSans600'>
                                                {translateLanguage("SELECT_THE_OPPORTUNITY_LEADER")}
                                            </FormLabel>
                                            <Select
                                                labelId="filter-type-label"
                                                value={selectedOpportunity}
                                                MenuProps={{
                                                    sx: {
                                                        "&& .Mui-selected": {
                                                            color: "var(--primary)",
                                                            background: "var(--primary-light)",
                                                        },
                                                    },
                                                }}
                                                sx={{
                                                    "& [aria-expanded=true]": {
                                                        background: "var(--primary-light)",
                                                    },
                                                }}
                                                className='reportSelectorFilterType NunitoSans700'
                                                onChange={(event) => {
                                                    setSelectedOpportunity(event.target.value)
                                                }}
                                            >
                                                {opportunityList.length > 0 && opportunityList.map((opportunity) => (
                                                    <MenuItem className='NunitoSans600' key={opportunity.opportunityId} value={opportunity.opportunityId} >
                                                        {opportunity.leaderName}
                                                    </MenuItem>

                                                ))}
                                            </Select>
                                        </FormControl>
                                        <Button
                                            style={{ border: "1px solid " }}
                                            disabled={opportunityPaymentReportCSV.length == 1}
                                            className='!font-semibold !text-base NunitoSans800 !rounded !bg-opacity-15 !bg-[var(--blue-light)] !text-[var(--blue)] !py-2 !border-2 !border-[var(--blue)] !px-3'>
                                            <FileDownloadIcon className='mr-2' />
                                            <CSVLink data={opportunityPaymentReportCSV} filename={"OpportunityPaymentReport.csv"}>
                                                {translateLanguage("DOWNLOAD_REPORT")}
                                            </CSVLink>
                                        </Button>
                                    </Box>

                                    <Box style={{ width: '100%', maxWidth: "2100px" }} className='dataGridTable oppurtunitiesGridTable !mt-3'>
                                        <DataGrid
                                            rows={
                                                paymentsData.filter((
                                                    payment: { opportunity_info: any; paymentType: string; }
                                                ) =>
                                                    payment.paymentType === "OPPORTUNITY"
                                                    && payment.opportunity_info.opportunityId == selectedOpportunity
                                                )}
                                            columns={columns}
                                            autoHeight={true}
                                            loading={tableLoader}
                                            getRowId={(row) => row.id}
                                            pageSizeOptions={[10]}
                                            className='tableHeaderColor commonBorderRadius commonShadow'
                                            localeText={customLocaleText}
                                            paginationMode={'client'}
                                        />
                                    </Box>
                                </Box>
                            </>

                        )}
                    </Box>

                </Box>
            </Box >
        </>
    );
}

export default Report
