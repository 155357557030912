import { Box } from '@mui/material';
import { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import SearchBox from '../../components/common/SearchBox';
import FeedbackDatagrid from '../../components/Datagrids/FeedbackDatagrid';
import { RootStateType } from '../../store';
import { useSelector } from 'react-redux';
import CallIcon from '@mui/icons-material/Call';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ReportIcon from '@mui/icons-material/Report';
import NotesIcon from '@mui/icons-material/Notes';
import CommonHeaderText from '../../components/common/CommonHeaderText';
import { translateLanguage } from '../../translation';

export default function Feedback() {
    const [filterType, setFeedbackFilter] = useState("note");
    const [paginationReset, setPaginationReset] = useState<boolean>(false);
    const [searchText, setSearchText] = useState("");
    const feedbackList = useSelector((state: RootStateType) => state?.feedback);

    const handleTabChange = (event: any, newValue: any) => {
        setFeedbackFilter(newValue);
        setPaginationReset(true); // Trigger pagination reset
    };

    return (
        <>
            <Box className="!py-5 !px-5" sx={{ maxWidth: "2000px" }}>
                <CommonHeaderText headerName="Feedback" />
                <div className='!mb-5 !w-full !gap-5 xl:!flex !items-center !mt-4'>
                    <Box className="xl:!flex !grid !items-center !w-full !justify-between !gap-3 ">
                        <Box className="!border commonShadow !bg-[var(--primary)] tabsRadius xl:!mt-0 !mt-4">
                            <Tabs value={filterType} onChange={handleTabChange} className=" !overeflow-scroll tabsScroll" >
                                <Tab className='lg:!text-base !text-sm xl:!min-w-[180px] !font-semibold' icon={<NotesIcon />} label={`${translateLanguage("NOTE")} (${feedbackList?.totalNotesCount})`} value="note" />
                                <Tab className='lg:!text-base !text-sm xl:!min-w-[180px] !font-semibold' icon={<ReportIcon />} label={`${translateLanguage("COMPLAINT")} (${feedbackList?.totalComplaintCount})`} value="complaint" />
                                <Tab className='lg:!text-base !text-sm xl:!min-w-[180px] !font-semibold' icon={<SettingsSuggestIcon />} label={`${translateLanguage("SUGGESTION")} (${feedbackList?.totalSuggestionCount})`} value="suggestion" />
                                <Tab className='lg:!text-base !text-sm xl:!min-w-[180px] !font-semibold' icon={<CallIcon />} label={`${translateLanguage("CONTACT_US")} (${feedbackList?.totalContactusCount})`} value="contact_us" />
                            </Tabs>
                        </Box>
                        <SearchBox searchText={searchText} setSearchText={setSearchText} />
                    </Box>
                </div>
                <FeedbackDatagrid searchText={searchText} filterType={filterType}
                paginationReset={paginationReset} setPaginationReset={setPaginationReset} />
            </Box>
        </>
    )
}

