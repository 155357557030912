import { useContext, useState } from "react";
import { Table, TableBody, Typography, TableCell, TableRow } from "@mui/material";
import moment from 'moment';
import { UserDataContext } from "./UserDetail";
import { ContextInterface } from "../../../services/dataType/api_interfaces/users";
import { EnhancedTableHead } from "../../../components/common/tableFunctions";
import { Link } from "react-router-dom";
import ProfileNameEmailUser from "../../../components/common/ProfileNameEmailUser";
import { translateLanguage } from "../../../translation";
import { convertToHijri } from "../../../utils/convertToHijri";

interface Payment {
    id: any;
    created_at: Date;
    amount: string;
    post_info?: any
    remarks: string;
}

const headCells = [
    { id: 'user_detail;', label: translateLanguage("GROOM"), sortable: false },
    { id: 'remarks;', label: translateLanguage("REMARKS"), sortable: false },
    { id: 'wedding_date;', label: translateLanguage("WEDDING_DATE"), sortable: true },

    { id: 'amount', label: translateLanguage("AMOUNT"), sortable: true },
    { id: 'created_at', label: translateLanguage("PAID_ON"), sortable: true },
];

function PalacePaymentTable() {
    const contextValue: ContextInterface = useContext(UserDataContext);
    const { userData } = contextValue

    const [sortedData, setSortedData] = useState(userData.data.palaceBasicInfo.paymentsInfo as Payment[] || [])

    if (sortedData.length === 0) {
        return <Typography variant="h6" className="!justify-center !flex !py-8">
            {translateLanguage("NO_PAYMENTS_FOUND")}
        </Typography>;
    }

    return (
        <Table>
            <EnhancedTableHead
                headCells={headCells}
                sortedData={sortedData}
                setSortedData={setSortedData}
                dependentEffect={userData.data.palaceBasicInfo.paymentsInfo}
            />
            <TableBody>
                {sortedData.length && sortedData.map((obj, index) => (
                    <TableRow key={index}>

                        <TableCell>
                            <Link to={`/opportunity/detail/${obj.post_info.id}`} style={{ textDecoration: 'none' }} >
                                <ProfileNameEmailUser profileImageAvatar={obj.post_info.groom?.photo} profileName={obj.post_info.groom?.name} />
                            </Link>

                        </TableCell>
                        <TableCell className="!whitespace-nowrap truncate max-w-xs">
                            {obj.remarks}
                        </TableCell>
                        <TableCell className="!whitespace-nowrap">
                            {convertToHijri(obj.post_info.wedding_date)}
                        </TableCell>
                        <TableCell className="!whitespace-nowrap">
                            {obj.amount}
                        </TableCell>
                        <TableCell className="!whitespace-nowrap">
                            {moment(obj.created_at).format('YYYY-MM-DD')}
                            <br />
                            {moment(obj.created_at).format('h:mm A')}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export default PalacePaymentTable;
