import { Box, IconButton } from '@mui/material';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const ViewIconButton = (props?: any) => {
    const { className, style } = props;
    return (
        <Box>
            <IconButton className={` !flex !min-w-[60px] !capitalize !whitespace-nowrap !gap- !font-semibold !items-center !px-3 !text-[14px] !rounded-md !bg-[var(--primary)] !text-white ${className}`} style={style}>
                <RemoveRedEyeIcon
                    style={{ cursor: "pointer" }}
                    className="!w-4 !h-4 !mr-[6px]"
                />
            </IconButton>
        </Box>
    )
}

export default ViewIconButton