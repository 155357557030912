
import { useState, useContext } from "react";
import { Box, Typography, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import moment from 'moment';
import { EnhancedTableHead } from "../../../components/common/tableFunctions";
import { UserDataContext } from "./UserDetail";
import { ContextInterface } from "../../../services/dataType/api_interfaces/users";
import ProfileNameEmailUser from "../../../components/common/ProfileNameEmailUser";
import ViewIconButton from "../../../components/common/ViewIconButton";
import { translateLanguage } from "../../../translation";
import { convertToHijri } from "../../../utils/convertToHijri";

interface Opportunity {
	id: any;
	leader: {
		id: string;
		name: string;
		email: string;
		photo?: string;
	};
	created_at: string;
	relation_with_groom: {
		relation: string;
	};
	total_base_amount_recieved: string;
	title: string;
	total_fees_recieved: string;
	total_amount_recieved: string;
	contributors: any[];
}

const headCells = [
	{ id: 'user', label: translateLanguage("LEADER"), sortable: false },
	{ id: 'created_at', label: translateLanguage("CREATED_AT"), sortable: true },

	{ id: 'title', label: translateLanguage("TITLE"), sortable: false },
	{ id: 'contributers', label: translateLanguage("CONTRIBUTORS"), sortable: false },
	{


		id: 'total_base_amount_recieved', label: (<>
			<span className='!whitespace-nowrap'>
				{translateLanguage("BASE_AMOUNT")}
				(SAR)
			</span>
		</>
		) as any, sortable: true
	},
	{
		id: 'total_fees_recieved', label: (<>
			<span className='!whitespace-nowrap'>
				{translateLanguage("FEES")}
				(SAR)
			</span>
		</>
		) as any, sortable: true
	},
	{
		id: 'total_amount_recieved', label: (<>
			<span className='!whitespace-nowrap'>
				{translateLanguage("TOTAL_AMOUNT")}
				(SAR)
			</span>
		</>
		) as any, sortable: true
	},
	{ id: 'action', label: translateLanguage("ACTION"), sortable: false },
]
function PostTable() {

	const contextValue: ContextInterface = useContext(UserDataContext);
	const { userData } = contextValue;


	const [sortedData, setSortedData] = useState(userData.data.groomBasicInfo.opportunityList as Opportunity[] || [])

	if (sortedData.length === 0) {
		return <Typography variant="h6" className="!justify-center !flex !py-8">
			{translateLanguage("NO_OPPORTUNITY_FOUND")}
		</Typography>;
	}

	return (
		<Table>
			<EnhancedTableHead
				headCells={headCells}
				sortedData={sortedData}
				setSortedData={setSortedData}
				dependentEffect={userData.data.groomBasicInfo.opportunityList}
			/>
			<TableBody>
				{sortedData.map((obj, index) => (
					<TableRow key={index}>
						<TableCell>
							<Link to={`/users/detail/${obj.leader.id}`} style={{ textDecoration: 'none' }} >
								<ProfileNameEmailUser profileImageAvatar={obj.leader.photo} profileName={obj.leader.name} />

							</Link>
						</TableCell>

						<TableCell className="!whitespace-nowrap">
							{convertToHijri(obj.created_at)}
							<br />
							{moment(obj.created_at).format('h:mm A')}
						</TableCell>

						<TableCell className="">
							{obj.title?.length !== 0 ? (
								<Box className="!min-w-[200px]  textOverflowTwoLines">
									{obj.title}
								</Box>
							) : (
								"---"
							)}
						</TableCell>
						<TableCell className="!text-center">{obj.contributors.length}</TableCell>

						<TableCell className="!whitespace-nowrap">
							{obj.total_base_amount_recieved}
						</TableCell>

						<TableCell className="!whitespace-nowrap">
							{obj.total_fees_recieved}
						</TableCell>


						<TableCell className="!whitespace-nowrap">
							{obj.total_amount_recieved}
						</TableCell>

						<TableCell>
							<Link to={`/opportunity/detail/${obj.id}`}>
								<ViewIconButton />
							</Link>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
}

export default PostTable;

